.site-content > .content-wrapper .content {
  &.terms-and-conditions {
    @include content-container-fluid;

    .form-group {
      &.submit {
        @media screen and (max-width: 1023px) {
          button,
          .button {
            display: block;
          }
        }
      }
    }
  }
}
