@import '../abstracts/colors';

$dark-grey: rgb(110, 110, 110);

$label-color: black;
$label-font-family: $branded-font-stack;
$label-font-size: 13px; // lock
$label-font-weight: normal;
$label-line-height: inherit;
$label-padding: 0px;
$label-margin: 0px 0px 0px 0px;

$focus-color: $brand-primary-color;

$input-color: black;
$input-color-placeholder: #999;
$input-background-color: white;
$input-background-color-disabled: $very-light-grey;
$input-border-color: rgb(204, 204, 204);
$input-border: 2px solid $input-border-color; // lock
$input-border-radius: 4px; // lock
$input-font-family: $branded-font-stack;
$input-font-size: 16px;
$input-font-weight: 400;
$input-line-height: inherit;
$input-height: 45px;
$input-padding: 8px;
$input-margin-y: 5px;
$input-margin: $input-margin-y 0;

// $input-color-check: black;
$input-color-check: white;

$input-color-range-track: black;
$input-range-track-border: 1px solid transparent;

$input-color-range-thumb: white;
$input-range-thumb-border: 1px solid #999;

$select-color: black;
$select-background-color: white;
$select-background-color-disabled: $input-background-color-disabled;
$select-border: $input-border;
$select-border-radius: $input-border-radius;
$select-font-family: $input-font-family;
$select-font-size: $input-font-size;
$select-font-weight: $input-font-weight;
$select-height: $input-height;
$select-padding: 6px 40px 8px 8px;
$select-margin: $input-margin;

$image-input-width: 100%;
$image-input-height: 256px;
$image-input-aspect-ratio: 0%;
$image-input-margin: 0px 0px 5px 0px;

$label-required-color: $label-color;

$label-required-asterisk-content: '*';
$label-required-asterisk-color: $label-required-color;
$label-required-asterisk-font-family: $label-font-family;
$label-required-asterisk-font-size: $label-font-size;
$label-required-asterisk-font-weight: $label-font-weight;
$label-required-asterisk-padding: $label-padding;
$label-required-asterisk-margin: $label-margin;

$label-help-color: white;
$label-help-background-color: blue;
$label-help-padding: 0px;
$label-help-margin: 0px 0px 0px 5px;
$label-help-width: 13px;
$label-help-height: 13px;

$label-help-tooltip-color: white;
$label-help-tooltip-background-color: blue;
$label-help-tooltip-font-family: $branded-font-stack;
$label-help-tooltip-font-size: 15px;
$label-help-tooltip-font-weight: normal;
$label-help-tooltip-padding: 8px 21px 8px 21px;
$label-help-tooltip-margin: 0px 0px 0px 0px;
$label-help-tooltip-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);

$label-description-color: $label-color;
$label-description-font-family: $label-font-family;
$label-description-font-size: 15px;
$label-description-font-weight: normal;
$label-description-margin: 8px 0 0 0;
$label-description-padding: $label-padding;

$label-error-color: white;
$label-error-background-color: firebrick;
$label-error-font-family: $label-font-family;
$label-error-font-size: 15px;
$label-error-font-weight: normal;
$label-error-margin: 15px 0px 0px 0px;
$label-error-padding: 10px;

$form-error-color: #b22222;
$form-error-background-color: transparent;
$form-error-font-family: $label-font-family;
$form-error-font-size: inherit;
$form-error-font-weight: normal;
$form-error-margin: 0px 0px 0px 0px;
$form-error-padding: 0px;

@import '../../../app/q360-comps/form/src/form.scss';

$calendar-header-color: black;
$calendar-header-background-color: white;
$calendar-header-font-family: inherit;
$calendar-header-font-size: 14px;
$calendar-header-font-weight: normal;
$calendar-header-line-height: inherit;
$calendar-header-padding: 4px 0px 4px 0px;
$calendar-header-margin: 0px 0px 4px 0px;

$calendar-arrow-color: $calendar-header-color;
$calendar-arrow-width: 20px;
$calendar-arrow-height: 20px;
$calendar-arrow-margin: 0px 4px;

$calendar-color: black;
$calendar-background-color: white;
$calendar-font-family: inherit;
$calendar-font-size: 14px;
$calendar-font-weight: normal;
$calendar-line-height: inherit;

$calendar-weekend-color: black;
$calendar-weekend-font-family: $calendar-font-family;
$calendar-weekend-font-size: $calendar-font-size;
$calendar-weekend-font-weight: $calendar-font-weight;
$calendar-weekend-line-height: $calendar-line-height;

// $calendar-today-color: red;
$calendar-today-background-color: $calendar-background-color;
$calendar-today-font-family: $calendar-font-family;
$calendar-today-font-size: $calendar-font-size;
$calendar-today-font-weight: $calendar-font-weight;
$calendar-today-line-height: $calendar-line-height;

$calendar-overflow-color: #cccccc;
$calendar-overflow-font-family: $calendar-font-family;
$calendar-overflow-font-size: $calendar-font-size;
$calendar-overflow-font-weight: $calendar-font-weight;
$calendar-overflow-line-height: $calendar-line-height;

$calendar-range-color: black;
$calendar-range-background-color: lightgray;
$calendar-range-font-family: $calendar-font-family;
$calendar-range-font-size: $calendar-font-size;
$calendar-range-font-weight: $calendar-font-weight;
$calendar-range-line-height: $calendar-line-height;

$calendar-range-cap-color: white;
$calendar-range-cap-background-color: gray;

// $calendar-selected-color: white;
// $calendar-selected-background-color: black;
$calendar-selected-font-family: $calendar-font-family;
$calendar-selected-font-size: $calendar-font-size;
$calendar-selected-font-weight: $calendar-font-weight;
$calendar-selected-line-height: $calendar-line-height;

$calendar-hover-color: $calendar-color;
$calendar-hover-background-color: #cccccc;
$calendar-hover-font-family: $calendar-font-family;
$calendar-hover-font-size: $calendar-font-size;
$calendar-hover-font-weight: $calendar-font-weight;
$calendar-hover-line-height: $calendar-line-height;

$calendar-day-border-style: none;
$calendar-day-border-width: 0px;
$calendar-day-border-color: transparent;
$calendar-day-border-radius: 15px;
$calendar-day-height: 30px;
$calendar-day-width: 30px;
// $calendar-day-padding: 2px;

$calendar-month-border-style: none;
$calendar-month-border-width: 0px;
$calendar-month-border-color: transparent;
$calendar-month-border-radius: 21px;
$calendar-month-height: 42px;
$calendar-month-width: 42px;
$calendar-month-padding: 2px;

$calendar-year-border-style: none;
$calendar-year-border-width: 0px;
$calendar-year-border-color: transparent;
$calendar-year-border-radius: 21px;
$calendar-year-height: 42px;
$calendar-year-width: 42px;
$calendar-year-padding: 2px;

$calendar-day-of-week-color: gray;
$calendar-day-of-week-font-family: $calendar-font-family;
$calendar-day-of-week-font-size: ($calendar-font-size * calc(12/14));
$calendar-day-of-week-font-weight: bold;
$calendar-day-of-week-line-height: $calendar-line-height;

@import '../../../app/q360-comps/calendar/src/calendar.scss';

$clock-color: black;
$clock-background-color: white;
$clock-font-family: inherit;
$clock-font-size: 16px;
$clock-font-weight: normal;
$clock-line-height: inherit;
$clock-padding: 0px;
$clock-margin: 0px 0px 0px 0px;

$clock-arrow-color: $clock-color;
$clock-arrow-width: 40px;
$clock-arrow-height: 40px;
$clock-arrow-margin: 10px;

@import '../../../app/q360-comps/clock/src/clock.scss';

$date-time-input-padding: 0px 10px 0px 0px;

$date-time-input-popup-background-color: white;
$date-time-input-popup-width: 250px;
$date-time-input-popup-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
$date-time-input-popup-margin: 28px 0 0 auto;

$date-time-input-popup-background-color-mobile: rgba(0, 0, 0, 0.85);
$date-time-input-popup-width-mobile: 320px;
$date-time-input-popup-box-shadow-mobile: none;
$date-time-input-popup-margin-mobile: auto;

$date-time-input-popup-footer-width: calc(100% - 10px);
$date-time-input-popup-footer-border: 1px solid lightgray;
$date-time-input-popup-footer-padding: 5px 5px 10px 5px;

$date-time-input-clear-color: black;
$date-time-input-clear-font-family: inherit;
$date-time-input-clear-font-weight: normal;
$date-time-input-clear-font-size: 14px;
$date-time-input-clear-line-height: inherit;
$date-time-input-clear-margin: 0px;

$date-time-input-clear-font-size-mobile: 20px;
$date-time-input-clear-line-height-mobile: inherit;
$date-time-input-clear-margin-mobile: 5px;

$date-time-input-button-background-color: transparent;
$date-time-input-button-border: none;
$date-time-input-button-border-radius: 0px;
$date-time-input-button-padding: 6px 0px;
$date-time-input-button-margin: 0px;
$date-time-input-button-width: auto;
$date-time-input-button-height: auto;

$date-time-input-calendar-icon-width: 20px;
$date-time-input-calendar-icon-height: 22.38px;
$date-time-input-calendar-icon-padding: 0px;

$date-time-input-calendar-icon-fill: white;
$date-time-input-calendar-icon-stroke: #231f20;
$date-time-input-calendar-icon-line-stroke: #231f20;
$date-time-input-calendar-icon-header-fill: #231f20;
$date-time-input-calendar-icon-header-line-one-stroke: #898889;
$date-time-input-calendar-icon-header-line-two-stroke: #626363;
$date-time-input-calendar-icon-binder-hole-fill: #c3c3c2;
$date-time-input-calendar-icon-binder-line-stroke: #231f20;

$date-time-input-clock-icon-width: 20px;
$date-time-input-clock-icon-height: 20px;
$date-time-input-clock-icon-padding: 1px 0px;

$date-time-input-clock-icon-fill: white;
$date-time-input-clock-icon-stroke: #231f20;
$date-time-input-clock-icon-hour-stroke: #231f20;
$date-time-input-clock-icon-minute-stroke: #231f20;
$date-time-input-clock-icon-center-fill: #231f20;

@import '../../../app/q360-comps/date-time-input/src/date-time-input.scss';

@mixin selectFocus {
  border-color: $focus-color;
  outline: black dotted 3px;
  outline-offset: 2px;
}

@mixin select {
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-min-logical-width: 184px;
  -moz-appearance: none;
  appearance: none;
  color: $select-color;
  border: $select-border;
  border-radius: $select-border-radius;
  font-family: $select-font-family;
  font-size: $select-font-size;
  font-weight: $select-font-weight;
  max-width: 100%;
  height: $select-height;
  padding: $select-padding;
  margin: $select-margin;
  background: $select-background-color url('../../../assets/img/svg-icons/caret_down.svg') no-repeat right;
  background-size: 38px 19px;
  background-position-x: right;

  width: calc(100% - 7px);
  &:focus {
    @include selectFocus;
  }

  &::-ms-expand {
    display: none;
  }
}

#root .react-select {
  .multiselect {
    @include select;
    height: auto;
    min-height: $select-height;

    &.react-select__control--is-focused {
      @include selectFocus;
    }

    padding: 0 40px 0 8px;

    > div:first-child {
      padding: 0;
    }

    .placeholder {
      color: black;
    }

    .input {
      input {
        height: auto;
      }
    }

    .indicators-container {
      display: none;
    }
  }
  .react-select__menu {
    z-index: $footer-zindex-desktop + 1;
  }
}

label,
legend {
  box-sizing: border-box;
  display: inline-block;
  color: $label-color;
  font-family: $label-font-family;
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  padding: $label-padding;
  margin: $label-margin;
}

.form-error {
  margin: 0px 0px 15px 0px;
}

.form-group {
  margin: 0px 0px 15px 0px;

  &:last-child {
    margin-bottom: 0px;
  }

  label,
  legend {
    display: block;
    color: $label-color;
    font-family: $label-font-family;
    font-size: $label-font-size;
    font-weight: $label-font-weight;
    padding: $label-padding;
    margin: $label-margin;

    & > span {
      display: block;
    }
  }
  h3 {
    margin-top: 2em;
    margin-bottom: 0;

    + p {
      margin-top: 0;
    }
  }
  &.error {
    label > span > span {
      color: #b10233 !important;
    }
    select, input, textarea {
      border: 2px solid #b10233;
    }
    input, textarea {
      padding-right: 35px; // Space for the icon ↓
      background-image: url('../../../assets/img/svg-icons/error.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 5px);
      background-size: 23px;
    }
  }
  p.error {
    box-sizing: content-box;
    display: inline;
    position: static;
    color: #b22222;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: $form-error-font-weight;
    margin: 0;
    padding: 0 0 0 14px;
  }

  // Hide until adequately styled. Terribly broken in IE11 without set dimensions.
  button.help {
    display: none;
  }
}

.form-group {
}

:disabled,
[disabled] {
  cursor: not-allowed;
}

input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
textarea {
  box-sizing: border-box;
  color: $input-color;
  background-color: $input-background-color;
  border: $input-border;
  border-radius: $input-border-radius;
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  height: $input-height;
  padding: $input-padding;
  margin: $input-margin;

  width: calc(100% - 7px);
  &:focus {
    border-color: $focus-color;
    outline-color: black;
    outline-style: dotted;
    outline-width: 3px;
    outline-offset: 2px;
  }

  &::placeholder {
    color: $input-color-placeholder;
  }
}

input[readonly] {
  border-color: $input-border-color;
  color: $brand-secondary-color;
  background-color: $input-background-color-disabled;

  &:focus {
    border-color: #C9D1DF;
  }
}

input[type='color'] {
  box-sizing: border-box;
  color: $input-color;
  background-color: $input-background-color;
  border: $input-border;
  border-radius: $input-border-radius;
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  width: 100%;
  height: $input-height;
  padding: $input-padding;
  margin: $input-margin;

  // width: calc(100% - 7px);
  &:focus {
    border-color: $focus-color;
    outline-color: black;
    outline-style: dotted;
    outline-width: 3px;
    outline-offset: 2px;
  }
}

input[type='search'] {
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type='date'],
input[type='datetime-local'],
input[type='time'],
input[type='month'],
input[type='week'] {
  -webkit-appearance: none;
  -webkit-min-logical-width: 184px;
}

textarea {
  resize: vertical;
  height: 6rem;
  &:focus {
    border-color: $focus-color;
    outline-color: black;
    outline-style: dotted;
    outline-width: 3px;
    outline-offset: 2px;
  }
}

select {
  @include select;
}

select[multiple],
select[size] {
  box-sizing: border-box;
  -webkit-appearance: initial;
  -webkit-min-logical-width: 184px;
  -moz-appearance: initial;
  appearance: initial;
  color: $input-color;
  background: $input-background-color;
  border: $input-border;
  border-radius: $input-border-radius;
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  padding: 0px;
  margin: $input-margin;
  width: 100%;
  height: auto;
  overflow: auto;

  option {
    padding: calc($input-padding / 2) $input-padding;
  }
}

label.checkbox {
  display: block;
  margin: 7px 0px 0px 0px;
  font-size: $input-font-size;
}

.ac-dashboard--option-toggle{
  width: 45px !important;
  height: 25px !important;

  input[type='checkbox'] {
    margin-top: 25px !important;
    width: 45px !important;
    height: 20px !important;
    z-index: 0 !important;
    opacity: 0 !important;
  }
}

.ac-dashboard--option-toggle input[type='checkbox'][disabled] {
  opacity: 0 !important;
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 0px !important;
  height: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}

input[type='checkbox'] + span + div {
  display: inline-block;
  float: right;
  width: calc(100% - 34px);
}

input[type='checkbox'] + span {
  display: inline-block;
  box-sizing: border-box;
  color: $label-color;
  background-color: transparent;
  border: 2px solid $dark-grey;
  font-family: $label-font-family;
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin: 0px 10px 0px 0px;
  vertical-align: middle;
  text-align: center;
  position: relative;
  top: -1px;
  margin-left: 4px;
}

input[type='checkbox'] + span:before {
  content: '';
}

input[type='checkbox']:checked + span {
  background-color: $dark-grey;
}
input[type='checkbox']:checked + span:before {
  display: block;
  content: '';
  width: 10px;
  height: 5px;
  border-left: $input-color-check 1px solid;
  border-bottom: $input-color-check 1px solid;
  transform: rotate(-45deg);
  position: relative;
  left: 3px;
  top: 3px;
}
input[type='checkbox'][disabled] {
  opacity: 0.4;
}
input[type='checkbox']:focus + span {
  outline: none;
  outline-color: black;
  outline-style: dotted;
  outline-width: 3px;
  outline-offset: 2px;
}

input[type='checkbox']:disabled + span {
  background-color: $input-background-color-disabled;
}

// CHECKBOX STYLES SHOULD PROBABLY BE COPIED OVER TO RADIO TOO
// but this depends on what the radio styles should be

fieldset.checkbox {
  border: none;
  padding: 0px;
  margin: 0px;

  legend {
    font-family: $label-font-family;
    font-size: 16px;
    font-weight: $label-font-weight;
    padding: $label-padding;
    margin: $input-margin;
    line-height: 1.2;
  }
}

label.radio {
  display: block;
  margin: $input-margin;
  font-size: $input-font-size;
}

input[type='radio'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 0px !important;
  height: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}

input[type='radio'] + span {
  display: inline-block;
  box-sizing: border-box;
  color: $label-color;
  background-color: $input-background-color;
  border: 2px solid #6e6e6e;
  border-radius: 11px;
  font-family: $label-font-family;
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin: 0px 10px 0px 0px;
  vertical-align: middle;
  text-align: center;
  position: relative;
  top: -1px;
}

input[type='radio'] + span + div {
  display: inline-block;
}

input[type='radio'] + span:before {
  content: '';
}

input[type='radio']:checked + span:before {
  display: block;
  content: '';
  background-color: $dark-grey;
  border-radius: 6px;
  width: 10px;
  height: 10px;
  position: relative;
  left: 3px;
  top: 3px;
}

input[type='radio']:focus + span {
  outline: none;
  // border-color: $focus-color;
  outline-color: black;
  outline-style: dotted;
  outline-width: 3px;
  outline-offset: 2px;
}

input[type='radio']:disabled + span,
input[type='radio']:disabled + span + .radio-label {
  // background-color: $input-background-color-disabled;
  opacity: 0.8;
  cursor: not-allowed;
}

fieldset.radio {
  border: none;
  padding: 0px;
  margin: 0px;

  legend {
    font-family: $label-font-family;
    font-size: $label-font-size;
    font-weight: $label-font-weight;
    padding: $label-padding;
    margin: $input-margin;
  }
}

input[type='range'] {
  box-sizing: border-box;
  -webkit-appearance: none;
  background: transparent;
  height: 21px;
}

@mixin rangeTrackBase {
  width: 100%;
  height: 7px;
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  background: $input-color-range-track;
  border: none;
  border-radius: 0px;
  @include rangeTrackBase();
}

input[type='range']::-moz-range-track {
  background: $input-color-range-track;
  border: $input-range-track-border;
  border-radius: 0px;
  @include rangeTrackBase();
}

input[type='range']::-ms-track {
  color: transparent;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  @include rangeTrackBase();
}

input[type='range']::-ms-fill-lower {
  background: $input-color-range-track;
  border: $input-range-track-border;
  border-radius: 0px;
}

input[type='range']::-ms-fill-lower {
  width: calc(100% - 7px);
}
@mixin rangeThumbFocus() {
  border-color: $focus-color;
  outline-color: black;
  outline-style: dotted;
  outline-width: 3px;
  outline-offset: 2px;
}
input[type='range']:focus::-ms-fill-lower {
  @include rangeThumbFocus();
}

input[type='range']::-ms-fill-upper {
  background: $input-color-range-track;
  border: $input-range-track-border;
  border-radius: 0px;
}
@mixin rangeThumbBase {
  background: $input-color-range-thumb;
  border: $input-range-thumb-border;
  border-radius: 11px;
  height: 21px;
  width: 21px;
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  @include rangeThumbBase();
  margin-top: -7px;
}

input[type='range']::-webkit-slider-thumb {
  width: calc(100% - 7px);
}
input[type='range']:focus::-webkit-slider-thumb {
  @include rangeThumbFocus();
}

input[type='range']::-moz-range-thumb {
  @include rangeThumbBase();
}

input[type='range']::-moz-range-thumb {
  width: calc(100% - 7px);
}
input[type='range']:focus::-moz-range-thumb {
  @include rangeThumbFocus();
}

input[type='range']::-ms-thumb {
  @include rangeThumbBase();
}

input[type='range']::-ms-thumb {
  width: calc(100% - 7px);
}
input[type='range']:focus::-ms-thumb {
  @include rangeThumbFocus();
}

label.file {
  display: inline-block;
  margin: $input-margin;
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 8px;
}

input[type='file'] + button, input[type='file'] + button.button {
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
}

input[type='file']:focus + button {
  outline: none;
  @include rangeThumbFocus();
}

.image-input {
  & > label.file {
    width: $image-input-width;
    margin-left: 1px;
    margin-right: 1px;
    text-align: center;
  }

  & > span {
    display: inline-block;
    width: $image-input-width;
    text-align: center;
  }

  & > .image-container {
    position: relative;
    width: $image-input-width;
    height: $image-input-height;
    padding-bottom: $image-input-aspect-ratio;
    overflow: hidden;
    border: $input-border;
    margin: $image-input-margin;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        width: auto;
        height: 100%;
        vertical-align: middle;
      }
    }
  }

  p.error {
    width: $image-input-width;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 10px;
  }
}
