$white: #ffffff;
$very-light-grey: #F2F2F3;
$light-grey: #cfcfcf;
$light-grey2: #bfbfbf;
$light-grey3: #a0a0a0;
$med-light-grey: #949494;
$med-light-grey2: #858585;
$med-grey: #6a6a6a;
$med-grey2: #616161;
$med-dark-grey: #5a5a5a;
$dark-grey: #292929;
$near-black: #171717;
$black: #000000;

$brand-primary-color: $black;
$brand-secondary-color: #4D4D4D; // Charcoal
$brand-green: #43B02A;
$brand-grey: #808285;
$brand-dark-blue: #002D82;

//Base Colors
$body-background-color: $very-light-grey;
$header-background-color: $white;
$footer-background-color: $white;
$box-shadow-color: #34383F29;

//Header
$header-font-color: $black;
$header-divider-color: $med-light-grey;
$header-subnav-background-color: $white;
$header-subnav-hover-background-color: $dark-grey;
$header-subnav-shadow-color: rgba($black, 0.13);
$header-subnav-divider-color: rgba($black, 0.13);
$header-subnav-font-color: $black;
$header-subnav-hover-font-color: $white;
$header-icon-color: $brand-primary-color;
$header-icon-hover-color: $brand-secondary-color;

//Footer
$footer-font-color: $brand-secondary-color;
$footer-link-color: $brand-secondary-color;

//Flag
$flag-color: $white;
$flag-background-color: $med-grey;
$flag-shadow-color: rgba($black, 0.8);

//Meters
$meter-title-font-color: $black;
$meter-asof-font-color: $med-light-grey;
$meter-display-font-color: $black;
$meter-tile-bg-color: $white;
