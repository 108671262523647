@import 'abstracts/colors';
@import 'variables/base';

// These override styles that are in packages - there is no sass variable that we can just change for these

:root {
  --pts-icon-size: 13px;
  @media screen and (min-width: 768px) {
    --pts-icon-size: 18px;
  }
}

/* stylelint-disable selector-max-id */
div#root {
  position: relative;
  z-index: 22;
  div.site.language
    > div.page
    > div.site-content
    > div.content-wrapper
    > div.content.profile
    > h1.content-header {
    display: none;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  form > div.form-group > label > span > span {
    display: inline-block;
  }
  /* stylelint-disable-next-line no-descending-specificity */
  form > div.form-group > label > span > span,
  form > div.form-group > label > span > span::after {
    color: $med-dark-grey;
  }

  // border-radius: 4px;
  // background-color: rgb(203, 23, 125);

  /* stylelint-disable-next-line no-descending-specificity */
  form > div.form-group:not(:first-child) > label > span > span {
    // space out form elements... probably want to add this as a variable somewhere, or change some variable somewhere...
    // searched "vertical" and "rhythm" but don't see anything...
    padding-top: 8px;
  }
  @media screen and (min-width: 768px) {
    form > div.form-group > label > span > span {
      padding-top: 11px;
    }
  }
}

nav.footer-nav > ul.nav-list > li.nav-list-item > a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 100;
  margin-bottom: 6px;
  margin-top: 9px;
}

nav.footer-nav > ul.nav-list > li.nav-list-item > ul.nav-list > li.nav-list-item > a {
  font-size: 11px;
}

// should really go to a global utils file...
.debugging-box {
  border: 1px solid red;
}

.overlay-container.active {
  z-index: 21;
  height: 100vh;
}

// .is-single-block-promotion {
.task-slide {
  .task > h1.title,
  .task > div.text {
    display: none;
  }
}

// TAKEOVER PROMOTION title and text was hidden
// due to the css right over this one so, this
// had to be more specific
.takeover {
  .takeover-overlay {
    display: flex;
    align-items: center;

    & > .content {
      margin: 20px;
      background-color: #fff;
      border-radius: 8px;
      max-width: 700px;
      width: 100%; //IE11

      @media screen and (min-width: 768px) {
        margin: 20px auto;
      }
    }

    .content-wrapper {
      .promotion.takeover > .content {
        background: #fff;
        margin: 0px;
        padding: 40px;
        border-radius: 8px;
      }
    }

    .task-slide {
      .task > h1.title,
      .task > div.text {
        display: block;
        color: #000;
      }
    }
  }
}

.task-slide {
  .task > h1.title.complete-title {
    display: block;
  }
}
// }

#skiptocontent a {
  position: absolute;
  padding: 6px;
  top: -40px;
  left: 0;
  color: white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  background: #bf1722;
  transition: top 1s ease-out;
  z-index: 100;

  &:focus {
    position: absolute;
    left: 0px;
    top: 0px !important;
    outline-color: transparent;
    transition: top 0.1s ease-in;
  }
}

// :focus:not(.focus-ring) {
//     outline-color: black;
//     outline-style: dotted;
//     outline-width: 3px;
//     outline-offset: 2px;
// }

a:focus,
div.avatar:focus-within,
button.div-role-button[type='button']:focus,
div[role='button']:focus,
img.banner-promo-image:focus {
  outline: 2px solid $focus-color;
}

a.banner-promotion-link {
  display: flex;
  flex-direction: column;
  height: 100%;

  .banner {
    height: 100%;

    .media {
      display: flex;
      flex-direction: column;
      flex: 1;

      > div {
        flex: 1;
      }
    }
  }
}

button.div-role-button[type='button'].common-single-block:focus,
.infoTile a:focus,
a.banner-promotion-link:focus
{
  outline: 5px solid $focus-color;
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus,
[data-whatinput='mouse'] *:focus,
[data-whatinput='touch'] *:focus,
[data-whatinput='mouse'] input[type='file']:focus + span,
[data-whatinput='touch'] input[type='file']:focus + span,
[data-whatinput='mouse'] .react-select__control--is-focused,
[data-whatinput='touch'] .react-select__control--is-focused {
  outline: none !important;
}
