.dev-tools {
  z-index: 999999;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #2d2d2d;
  border: solid 1px #646464;
  width: 300px;
  height: 28px;
  padding: 0px 2px;
  display: flex;

  .button {
    padding: 0px;
    margin: 2px;
    height: 22px;
    width: 22px;
    min-width: initial;
    line-height: 1;
    border: solid 1px #646464;
    border-radius: 4px;
    background-color: transparent;

    &[disabled] {
      opacity: 0.5;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
    }
  }

  .record {
    svg {
      fill: #ed1c24;
    }
  }

  .open {
    padding: 0px 0px 1px 0px;
    margin: 2px;
    height: 22px;
    width: 22px;
    line-height: 1;
    border: solid 1px #646464;
    border-radius: 4px;
    background-color: transparent;

    &[disabled] {
      opacity: 0.5;
    }

    svg {
      width: 20px;
      height: 20px;

      polygon:nth-child(1) {
        fill: #ccbb76;
      }

      polygon:nth-child(2) {
        fill: #e2d17f;
      }
    }
  }

  .play {
    svg {
      fill: #00a651;
    }
  }

  .close {
    align-self: flex-end;
  }

  ul {
    position: absolute;
    top: 28px;
    left: 0;
    padding: 0;
    list-style: none;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 11px;
    background: white;
    margin: 0;
    width: 100%;

    li {
      display: flex;
      align-items: center;

      &.request {
        color: blue;
      }

      &.request.defered {
        color: purple;
      }

      &.response.success {
        color: green;
      }

      &.response.failure {
        color: red;
      }

      &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05);
      }

      button.indicator {
        width: 9px;
        height: 9px;
        opacity: 0.1;
        background-color: black;
        border-radius: 5px;
        margin-left: 5px;
        margin-right: 5px;
        flex-shrink: 0;
        cursor: pointer;

        &.active {
          opacity: 1;
          cursor: default;
        }
      }

      span.text {
        padding: 2px;
      }

      .data {
        padding: 1px 0px 1px 0px;
        margin: 2px;
        height: 17px;
        width: 17px;
        line-height: 1;
        background-color: transparent;
        flex-shrink: 0;
        color: black;
        font-size: 10px;
      }

      .pause {
        padding: 1px 0px 0px 0px;
        margin: 2px 2px 2px auto;
        height: 17px;
        width: 17px;
        line-height: 1;
        background-color: transparent;
        opacity: 0.3;
        flex-shrink: 0;

        &.active {
          opacity: 1;
        }

        svg {
          width: 16px;
          height: 16px;
          fill: black;
        }
      }
    }
  }

  .data-overlay {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;

    .data-modal {
      background: white;
      margin: auto;
      padding: 20px;
      min-width: 800px;
      max-height: 80%;
      max-width: 80%;
      overflow: auto;

      .close {
        padding: 0px;
        margin: 2px 4px 2px 2px;
        height: 42px;
        width: 42px;
        line-height: 1;
        background-color: transparent;
        border: none;
        position: fixed;
        top: 5px;
        right: 5px;

        svg {
          width: 40px;
          height: 40px;
          fill: white;
        }
      }
    }
  }
}
