//*******************************************************************************************/
//*********** global-defaults/typography.scss - the starting file for global-defaults ************/
//*******************************************************************************************/

h1, h2, h3,
.h1, .h2, .h3,
strong {
  font-family: $bold-font-stack;
}

h1,
.h1 {
  font-size: 21px;
  color: $brand-primary-color;

  @media screen and (min-width: 768px) {
    font-size: 34px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 42px;
  }
}
