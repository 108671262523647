@mixin powerup-layout {
  .ragged-layout {
    .item-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;

      @include media-breakpoint-up('md') {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-up('xl') {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@mixin powerup-styles {
  .block-content__image {
    width: 6rem;
    height: 6rem;
    box-shadow: none;
    border-radius: 50%;
    object-fit: cover;
    font-family: 'object-fit: cover';

    @media screen and (min-width: 768px) {
      width: 7rem;
      height: 7rem;
    }
  }

  .powerup-content {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .powerup-content-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem;

      .name {
        font-size: 16px;
        font-weight: bold;

        @media screen and (min-width: 768px) {
          font-size: 21px;
        }
      }

      .category {
        font-size: 13px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }

        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
          font-family: 'object-fit: contain;';
          @media screen and (min-width: 768px) {
            height: 32px;
            width: 32px;
          }
        }
      }

      .points-and-time {
        display: flex;
        color: #000;
        margin-top: 0.5rem;

        .points {
          font-size: 13px;
          margin: 0;
          margin-right: 1rem;
          align-self: center;
        }

        .block__date {
          font-size: 13px;
          margin: 0;
          align-self: center;
        }

        svg.clock,
        svg.points {
          width: 12px;
          height: 12px;
          fill: #6e6e6e;
          display: inline-block;
          margin-right: 2px;
          margin-top: 2px;
        }
      }
    }
  }
}

.power-ups {
  padding: 1rem;
  flex: 1;
  height: 100%;
  @include content-container-fluid;

  @media screen and (min-width: 768px) {
    padding-top: 2rem;
  }

  h1 {
    margin: 0;
    padding-bottom: 1rem;
    @media screen and (min-width: 768px) {
      padding-bottom: 2rem;
    }
  }

  h1.content-header {
    font-size: 42px;

    @media screen and (max-width: 768px) {
      font-size: 21px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 34px;
    }
  }

  .react-tabs {
    &__tab-list {
      font-size: 13px;
      color: #000;
      display: inline-block;
      background: transparent;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }

    &__tab {
      @media screen and (min-width: 768px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      &:focus {
        box-shadow: 0 0 5px $brand-primary-color;
        border-color: $brand-primary-color;

        &:after {
          background: transparent;
        }
      }
    }

    &__tab--selected,
    &__tab--selected::after {
      background: transparent;
      border-color: transparent;
      border-bottom: 4px solid $brand-primary-color;
    }

    @include powerup-layout;

    .promotion.single_block {
      @include powerup-styles;
    }
  }
}

.archived-power-ups {
  background: #fff;
  .power-ups-detail-content {
    padding: 20px;
    @include media-breakpoint-up('md') {
      padding: 40px;
    }

    @include content-container-fluid;

    .container {

      @include powerup-styles;

      h3 {
        margin: 0 auto 1rem auto;
        color: $brand-primary-color;
        font-weight: 400;
        font-size: 21px;

        @include media-breakpoint-up('md') {
          margin-bottom: 2rem;
        }

        .badge {
          position: relative;
          bottom: 2px;
          background: #6e6e6e;
          border-radius: 50%;
          color: #fff;
          width: 1rem;
          padding: 0.1rem 0.4rem;
          font-size: 10px;
          vertical-align: middle;
          font-weight: 100;
        }
      }

      @include powerup-layout;

      .ragged-layout {
        .item-container {
          .tile.small {
            width: 100% !important;

            &:not(:last-child) {
              border-bottom: 1px solid #ccc;
            }

            .promotion.single_block {
              .media.media-main-base {
                div {
                  img.block-content__image {
                    width: 3rem;
                    height: 3rem;
                    box-shadow: none;
                    border-radius: 50%;
                    object-fit: cover;
                    font-family: 'object-fit: cover';

                    @media screen and (min-width: 768px) {
                      width: 4rem;
                      height: 4rem;
                    }
                  }

                  .powerup-content-details {
                    .name {
                      color: #000 !important;
                      font-weight: normal;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.is-media-background-inactive.is-single-block-promotion {
  .video-btns {
    margin-top: 0;
  }
  .task-container {
    ul.task-pager {
      @media screen and (min-width: 1024px) {
        top: -20.5rem;
      }
      @media screen and (min-height: 750px) {
        top: -22rem;
      }
      @media screen and (min-height: 800px) {
        top: -23.5rem;
      }
      @media screen and (min-height: 850px) {
        top: -25rem;
      }
      @media screen and (min-height: 900px) {
        top: -26.5rem;
      }
      @media screen and (min-height: 950px) {
        top: -28rem;
      }
      @media screen and (min-height: 1000px) {
        top: -29.5rem;
      }
    }
  }
}

// fix for content inside overlay on a power-up
.modal-container .modal-overlay .modal .content-container .content .overlay .content {
  padding: 30px;
}
