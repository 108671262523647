//*******************************************************************************************/
//*********** global-defaults/buttons.scss - the starting file for global-defaults ************/
//*******************************************************************************************/

// for <a tags that were essentially buttons and may be converted to buttons. Reset button styles.
.anchor-button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;

  /* show a hand cursor on hover; some argue that we
    should keep the default arrow cursor for buttons */
  cursor: pointer;
  // -webkit-appearance: button;
  // -webkit-writing-mode: horizontal-tb !important;
  // text-rendering: auto;
  // color: buttontext;
  // letter-spacing: normal;
  // word-spacing: normal;
  // text-transform: none;
  // text-indent: 0px;
  // text-shadow: none;
  // display: inline-block;
  // text-align: center;
  // align-items: flex-start;
  // cursor: default;
  // background-color: buttonface;
  // box-sizing: border-box;
  // margin: 0em;
  // font: 400 13.3333px Arial;
  // padding: 1px 6px;
  // border-width: 2px;
  // border-style: outset;
  // border-color: buttonface;
  // border-image: initial;
}
*[role='button'] {
  cursor: pointer;
}
// IMPORTANT FOR ACCESSIBILITY
.anchor-button:focus {
  outline: 2px solid $focus-color;
}

// highlight anchor tags to convert to anchor-buttons
// a[href="#"] {
//     border: 1pxx dotted red;
//     background: green;
// }

.button,
a.button {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: $button-height;
  border: $button-border;
  border-radius: $button-border-radius;
  font-family: $button-font-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  color: $button-color;
  padding: $button-padding;
  text-decoration: none;
  text-align: $button-text-align;
  box-shadow: $button-box-shadow;
  cursor: pointer;
  transition: all 0.3s;
  background-color: $button-background-color;
  text-transform: $button-text-transform;
  min-width: 5rem;
  width: 100%;
  margin-top: 1rem;

  @media screen and (min-width: 768px) {
    margin-left: 0.25rem;
    width: auto;
  }

  &:not(.nonstandard) {
    .icon {
      $size: 24px;
      $marginRight: 12px;

      position: absolute;
      top: calc(($button-height - $size - $button-border-width - 3px) / 2); // Minus 3px is arbitrary, but generally better.
      fill: $button-color;
      width: $size;
      height: $size;
      margin-right: $marginRight;

      // Would prefer to not need span but there is currently too much tech debt for better solution.
      & + span {
        margin-left: $size + $marginRight;
      }
    }

    &:not([disabled]):hover {
      background-color: $button-background-color-hover;
      border: $button-border-hover;
      color: $button-color-hover;

      .icon {
        fill: $button-color-hover;
      }
    }
  }

  &.appear-enabled:hover {
    // ensure there is no hover effect
    background-color: $button-background-color;
    border: $button-border;
  }
  &:focus {
    outline-color: black;
    outline-style: dotted;
    outline-width: 3px;
    outline-offset: 2px;
  }
  &.primary {
    background-color: $button-background-color;
    &:not([disabled]):hover {
      background-color: $button-background-color-hover;
    }
    &.appear-enabled:hover {
      // ensure there is no hover effect
      background-color: $button-background-color;
    }

    @media screen and (max-width: 1023px) {
      height: $button-height;
      border-radius: $button-border-radius;
      background-color: $button-background-color;
      color: $button-color;
      padding: $button-padding;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &.secondary-hover-flipped {
    color: $button-secondary-color-hover;
    background-color: $button-secondary-background-color;
    border: $button-secondary-border-hover;
    border-radius: $button-border-radius;
    text-decoration: none;
    &:hover,
    &.force-hover {
      color: $button-secondary-color;
      border: $button-secondary-border;
    }
  }
  &.secondary {
    color: $button-secondary-color;
    background-color: $button-secondary-background-color;
    border: $button-secondary-border;
    border-radius: $button-border-radius;
    text-decoration: none;
    &:not([disabled]):hover {
      color: $button-secondary-color-hover;
      background-color: $button-secondary-background-color-hover;
      border: $button-secondary-border-hover;
    }
    &.appear-enabled:hover {
      // ensure there is no hover effect (other than the cursor changing)
      color: $button-secondary-color;
      border: $button-secondary-border;
    }
    &:not(.appear-enabled)[disabled] {
      color: $button-secondary-color-disabled;
      background-color: $button-secondary-background-color-disabled;
      border: $button-secondary-border-disabled;
      cursor: not-allowed;
    }
  }
  &:not(.appear-enabled)[disabled] {
    background-color: $button-background-color-disabled;
    color: $button-color-disabled;
    border: $button-border-disabled;
    cursor: not-allowed;
  }
  &.small {
    display: inline-block;
    padding: 6px 13px;
    height: auto;
    width: auto;
    min-width: auto;
  }
  &.nonstandard {
    text-transform: initial;

    &.close {
      &.absolute {
        position: absolute;
      }
    }
  }
}
.appear-enabled[disabled],
.appear-enabled:disabled {
  cursor: not-allowed;
}

.secondary-btn {
  @extend .button, .secondary;
}

a.secondary-btn.patched-secondary-btn {
  background-color: white;
  border: none;
  display: inline-block;
}
a.secondary-btn.patched-secondary-btn:hover {
  background-color: white;
  border: none;
}

// When in forms
form button,
form .button,
.form-group button,
.form-group .button {
  display: inline-block;

  @media screen and (min-width: 1024px) {
    margin-right: 12px;
  }

}
