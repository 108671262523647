$block-content-padding: 1.5rem;

// originally named thinking that an "activity" is a "plan"
// so all this code is really for board with block :)
// NEEDS REWORK AS THERE IS A LOT OF CODE DUPLICATION
.tile.standalone .board-container {
  .board-header {
    background-color: $very-light-grey;
    @include content-container-fluid;

    & h1,
    & .header-title {
      color: $brand-primary-color;
      font-size: 26px;
      font-weight: 400;
      margin: 0;
      padding: 20px 0;
      display: flex;
      justify-content: flex-start;

      @media screen and (min-width: 768px) {
        padding: 36px 0px 16px 0px;
        font-size: 34px;
      }
      @media screen and (max-width: 767px) {
        font-size: 21px;
      }
      @media screen and (min-width: 1024px) {
        padding: 40px 0px 16px 0px;
        font-size: 42px;
      }
    }

    & .subtitle {
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
      }
    }

    .header-text {
      color: #6e6e6e;
      font-size: 1.3125rem;
      align-self: flex-start;
      padding: 0;

      @media screen and (min-width: 768px) {
        width: 54%;
        font-size: 1rem;
      }

      @media screen and (min-width: 1024px) {
        width: 50%;
      }

      @media screen and (max-width: 767px) {
        font-size: 0.8125rem;
      }
    }

    .status {
      padding: 0;

      @media screen and (min-width: 768px) {
        margin-top: 28px;
        text-align: right;
      }
      @media screen and (min-width: 1024px) {
        margin-top: 12px;
      }

      span {
        &.completed,
        &.of,
        &.total {
          font-size: 42px;

          @media screen and (max-width: 768px) {
            font-size: 21px;
          }
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 34px;
          }
          font-weight: 300;
        }

        &.completed {
          color: $brand-primary-color;
        }
        &.complete {
          font-weight: 300;
        }
      }
    }

    & h1 {
      font-size: 1rem;
      @media screen and (min-width: 768px) {
        width: 30%;
        text-align: right;
        margin: 0;
        & span {
          font-size: 2.125rem;
          letter-spacing: 2px;
        }
      }
      @media screen and (min-width: 1024px) {
        width: 26%;
      }
      & span.complete {
        letter-spacing: 0px;
        font-size: 1rem;
      }
      @media screen and (max-width: 767px) {
        & span {
          letter-spacing: 2px;
        }
      }
    }
  }
}

.status-position-wrapper {
  left: -80px;
  top: -32px;
}

@media screen and (max-width: 629px) {
  div.status-position-wrapper {
    left: -56px;
    top: -20px;
    // /* line 173, src/framework/scss/pages/_activity-challenge.scss */
    // .plan-block .status {
    //     top: 164px;
    //     height: 64px;
    //     width: 64px;
    //     border-radius: 36px;
    //     padding: 12px;
    // }
  }
}
@mixin activity-challenge-block-styles {
  padding: 0;
  border: 0;
  position: relative;
  height: auto;
  background: none;

  &:not([disabled]):hover {
    .block-content {
      &__image {
        filter: contrast(140%);
      }
    }
  }

  &:focus {
    outline: 5px solid $focus-color;
  }

  .block-content {
    &__image {
      display: block;
      width: 100%;
      object-fit: cover;
      filter: contrast(100%);
      transition: filter 0.5s ease-in-out;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    background: $white;

    & > div.text {
      display: block;
      flex: 1;
      width: 100%;
    }
  }

  .status {
    position: absolute;
    margin: 0;
    top: 164px;
    right: 6%;
    border: 4px solid $white;
    height: 48px;
    width: 48px;
    padding: 9px;
    border-radius: 24px;
  }

  &.complete {
    .status {
      background-color: $brand-primary-color;
    }
  }

  &.locked {
    .status {
      background-color: #6e6e6e;
    }
  }

  .text {
    padding: $block-content-padding $block-content-padding 0 $block-content-padding;
    font-size: 0.875rem;
    line-height: 1.43;
    color: $brand-secondary-color;

    .name {
      font-size: 1.25rem;
      font-family: $bold-font-stack;
      line-height: 1.3;
      margin-bottom: 4px;
    }

    p {
      margin: 0;
    }
  }

  .block__points,
  .block__date {
    display: inline-block;
    font-size: 0.8125rem;
    color: #6e6e6e;
    //padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .block__points {
    margin-bottom: 15px;
    margin-top: 0;
    @media screen and (min-width: 630px) {
      width: 40%;
      font-size: 1rem;
    }
    &-pts {
      font-size: 0.65rem;
    }
  }

  .block__date {
    font-size: 0.625rem;
    text-align: left;
    padding-left: 12px;
    margin-bottom: 12px;
    margin-top: 4px;
    @media screen and (min-width: 630px) {
      width: 60%;
      padding-left: 0;
      text-align: right;
      font-size: 0.8125rem;
    }

    .clock {
      display: inline-block;
      margin: 0 7px 0 0;
    }
  }
}
.single-block.plan-block .category-time-container {
  display: flex;
  width: 100%;
  padding: 0 $block-content-padding;

  .block__points {
    min-height: 32px;
    width: 60%;

    .tile.small & img {
      box-shadow: none;
      border-radius: 0;
      height: 32px;
      width: 32px;
      padding-right: 8px;
      object-fit: contain;
    }
  }
  .block__date {
    width: 40%;
    text-align: right;
  }
}

.plan-block {
  @include activity-challenge-block-styles;
}

.tile.standalone .board-container .promotion.board_with_block .board {
  background-color: $very-light-grey;
  @include content-container-fluid;
  padding-bottom: 24px;
  padding-top: 10px;

  .blocks {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .block {
      @include activity-challenge-block-styles;

      .block-content {
        display: block;

        img {
          height: 190px;
        }

        .text {
          .name {
            font-weight: normal;
          }
        }

        .block__points {
          padding-left: 24px;
        }
      }

      &.locked {
        background-color: white;
      }

      &.active {
        cursor: pointer;
      }
    }
  }
}

// block-header should really be just block container but that class is already taken as one of it's parents
@mixin block-containers-block-header {
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  & .media {
    margin: 0;

    @media screen and (min-width: 1024px) {
      & > div {
        // width: 50%;
        // position: relative;

        img {
          display: block; // inline is causing a scrollbar in IE 11
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }

        // .img-split img {
        //   height: 100vh;
        // }
      }
    }
  }

  .split-screen-container {
    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, 1fr);
      height: 100vh;
      // grid-template-areas:
      //   'media title'
      //   'media text-content'
      //   'media action-button';

      // IE11 CSS Grid Properties
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: 1fr max-content 1fr;
    }
  }

  .title {
    // font-size: 24px;
    text-align: center;
    padding: 10px 20px;
    color: $brand-primary-color;

    // Standard Font size
    font-size: 42px;
    @media screen and (max-width: 768px) {
      font-size: 21px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 34px;
    }

    @media screen and (min-width: 1024px) {
      //   display: inline-block;
      //   width: 50%;
      //   position: absolute;
      //   left: 50%;
      //   top: 16%;
      text-align: left;

      grid-column-start: 2;
      justify-self: flex-start;
      align-self: flex-end;
      // grid-area: title;

      // IE11 CSS Grid Properties
      -ms-grid-column: 2;
      -ms-grid-column-align: start;
      -ms-grid-row-align: end;
    }
  }

  .text {
    font-size: 15px;
    color: #000;
    padding: 0 32px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.6;

    @media screen and (min-width: 1024px) {
      //   display: inline-block;
      //   width: 44%;
      //   position: absolute;
      //   left: 50%;
      //   top: 22%;
      text-align: left;
      padding: 0 20px;
      grid-column-start: 2;
      // justify-self: flex-start;
      // grid-area: text-content;

      // IE11 CSS Grid Properties
      -ms-grid-column: 2;
      -ms-grid-row: 2;
    }

    // Specifically taraget IE. Since grid layouts aren't very well supported
    // by IE, limit the maximum height of the text content to 60% of the available
    // viewport height. This prevents content above and below the text from
    // being pushed off the screen when the text block is long.
    @media screen and (-ms-high-contrast: none) {
      max-height: 60vh;
      overflow: auto;
    }
  }

  .media {
    background-color: #f1f2f2;
    @media screen and (min-width: 1024px) {
      grid-row-start: 1;
      grid-row-end: 4;
      // grid-area: media;

      // IE11 CSS Grid Properties
      -ms-grid-row: 1;
      -ms-grid-row-span: 4;
    }
  }

  .action-button {
    @media screen and (min-width: 1024px) {
      // grid-area: action-button;
      // IE11 CSS Grid Properties
      -ms-grid-column: 2;
      -ms-grid-row: 3;
    }
  }
}

.block-containers-block-header {
  @include block-containers-block-header();
}

.block-containers-block-header + .task-container.carousel {
  @media screen and (min-width: 1024px) {
    width: 50%;
    position: absolute;
    top: 44%;
    right: 0;
    overflow: inherit;
  }
  .task-slide-container {
    .task-slide {
      .task {
        margin-top: 0;
        @media screen and (min-width: 1024px) {
          display: flex;
        }
        form {
          div.form-group.submit {
            &:first-child {
              margin-top: 0;
            }
            @media screen and (min-width: 768px) {
              text-align: center;
            }
            @media screen and (min-width: 1024px) {
              text-align: left;
            }
          }
        }
      }
      @media screen and (min-width: 1024px) {
        // width: 40%;
        text-align: left;
        padding: 0 20px;
      }
    }
  }
}

.tile .promotion .block-header.block-containers-block-header .media > div.taskImageOrVideo {
  @media screen and (min-width: 1024px) {
    padding: 0;
    height: 100%;
    & > div {
      height: 100%;
      & > div {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }
  }
}

.tile.standalone .board-container .promotion.board_with_block .block-container {
  .task-container .task-slide-container .task-slide {
    padding: 20px;

    @media screen and (min-width: 1024px) {
      margin-left: 0;
      // margin-top: 12rem;
    }

    .text {
      margin: 0;
      line-height: 1.6;
      // display: none;
    }

    p.complete,
    div.complete.was-p-tag {
      display: none;
    }
  }

  & .block-header {
    @include block-containers-block-header;
  }
}

.task-container ul.task-pager {
  pointer-events: none;
  cursor: default;
  @media screen and (min-width: 300px) {
    position: absolute;
    top: 1rem;
    left: 1.25rem;
  }
  @media screen and (min-width: 1024px) {
    position: absolute;
    left: 52%;
    // top: -17rem;
  }
  // @media screen and (min-width: 1050px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -18.25rem;
  // }
  // @media screen and (min-width: 1100px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -18.5rem;
  // }
  // @media screen and (min-width: 1150px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -19.25rem;
  // }
  // @media screen and (min-width: 1200px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -20rem;
  // }
  // @media screen and (min-width: 1250px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -21rem;
  // }
  // @media screen and (min-width: 1300px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -21.75rem;
  // }
  // @media screen and (min-width: 1380px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -23.25rem;
  // }
  // @media screen and (min-width: 1440px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -24.25rem;
  // }
  // @media screen and (min-width: 1500px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -25.5rem;
  // }
  // @media screen and (min-width: 1600px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -27.25rem;
  // }
  // @media screen and (min-width: 1700px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -29.25rem;
  // }
  // @media screen and (min-width: 1800px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -30.25rem;
  // }
  // @media screen and (min-width: 1900px) {
  //   position: absolute;
  //   left: 4%;
  //   top: -31.25rem;
  // }

  li {
    display: inline;

    a {
      display: inline-block;
      width: 26px;
      color: #6e6e6e;
      background-color: $white;
      border: 1px solid #6e6e6e;
    }

    &.active a {
      background-color: #6e6e6e;
      border-color: #6e6e6e;
    }

    &.complete a {
      background-color: $brand-primary-color;
      border-color: $brand-primary-color;
    }

    &.complete.active a {
      background-color: $brand-primary-color;
      border-color: $brand-primary-color;
    }

    &:not(:last-child):after {
      content: ' \2014';
      font-weight: 900;
      margin-left: 4px;
      color: #6e6e6e;
    }
  }
}

.modal-container {
  .modal-overlay {
    margin-top: 0;
    padding: 0;
    // SPLIT SCREEN
    // @media screen and (min-width: 1024px) {
    //     width: 50%;
    //     left: inherit;
    //     // padding-bottom: 132px;
    // }
    .modal {
      .close {
        background-color: transparent;
        margin: 1rem;
      }
      // SPLIT SCREEN - Survey task
      .content-container {
        .content {
          .survey-question-container,
          .quiz-question-container {
            form {
              @media screen and (min-width: 1024px) {
                padding-bottom: 240px;
              }
            }
          }
        }
      }
    }
  }
  &.scale {
    .modal-animate-enter {
      .modal {
        visibility: hidden;
        transform: translateX(60%);
        transition: transform $modal-transition-duration, visibility 0s $modal-transition-duration;
      }
    }

    .modal-animate-exit {
      .modal {
        transition: transform $modal-transition-duration;
      }
    }

    .modal-animate-enter.modal-animate-enter-active {
      .modal {
        visibility: visible;
        transform: translateX(0%);
      }
    }

    .modal-animate-exit.modal-animate-exit-active {
      .modal {
        visibility: hidden;
        transform: translateX(60%);
      }
    }
  }
}

.site.language .modal-container .modal-overlay {
  margin-top: 0;
}

.quiz-question-container,
.survey-question-container {
  position: relative;
  padding: 1.5rem;

  .content-body {
    .accordion {
      .panel {
        .panel-heading {
          background: none;

          button > div {
            padding: 0;
          }
        }

        .panel-body {
          & > div {
            margin-top: 0.3rem; // changed to .3 so select-promotion task in centene radio button borders dont get cut off
            margin-bottom: 0;

            .question-container > fieldset {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }

  .submit-quiz {
    position: absolute;
    width: 100%;
    bottom: -40%;
    .primary {
      width: 100%;
    }
  }

  .task-type {
    text-align: center;
    margin: 20px 0 0 0;
    text-transform: capitalize;
    color: #6e6e6e;
    font-size: 13px;
    font-weight: 700;
  }
  .task-title {
    text-align: center;
    color: $brand-primary-color;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
  }
  hr {
    margin: 40px 0;
    border: 1px solid #ccc;
  }
  .question-container {
    & > fieldset {
      margin-bottom: 32px;
    }

    .question {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.2;
      text-align: left;
    }

    .form-group {
      margin-bottom: 4px;
    }
  }
}

.quiz-question-container {
  padding: 1.5rem;
  p.message.correct,
  p.message.incorrect {
    color: $brand-primary-color;
    font-weight: bold;
  }

  .question-container {
    &.correct {
      .message {
        color: #408252;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &.incorrect {
      .message {
        color: #870c3f;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.overlay-container {
  .overlay {
    background: $white;
    .complete {
      border-radius: 50%;
      width: 108px;
      height: 108px;
      background-color: $brand-primary-color;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      margin: 4rem auto 4rem auto;

      @media screen and (min-width: 1024px) {
        width: 140px;
        height: 140px;
        border: 4px solid #fff;
      }
    }

    .content {
      padding: 0 2.5rem 4rem 2.5rem;

      .payout {
        color: #000;
        .actComplete {
          font-weight: bold;
        }
      }

      .points {
        color: $brand-primary-color;
      }

      p {
        color: #000;
        font-size: 16px;
        line-height: 1.6;
      }
    }
  }
}

.tile.standalone .board-container .promotion.board_with_block .block-container .block-header > .media > div {
  img,
  video {
    width: 100%;
    // height: 100%;
    @media screen and (min-width: 1024px) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
    left: 0;
  }
}

// QUICK FIX SPLIT SCREEN ON PROMO 69
.tile.small[data-item-key='promotions.p69'] {
  .promotion.single_block {
    .block-containers-block-header .split-screen-container {
      @media screen and (min-width: 1024px) {
        -ms-grid-rows: none;
      }
    }
  }
}

// .promotion .is-single-block-promotion .task-container .task-slide-container .task.datacapture {
//   @media screen and (min-width: 1024px) {
//     margin-top: -4rem;
//   }
//   @media screen and (min-width: 1440px) {
//     margin-left: 2rem;
//   }
// }

// .tile.large .promotion .is-media-background-inactive .task-container .task-slide-container .task.datacapture {
//   @media screen and (min-width: 1024px) {
//     margin-top: -4rem;
//   }
// }
// .tile.large .promotion > .is-media-background-base.is-media-background-inactive .task-container.carousel {
//   .task-slide-container {
//     .task-slide .task.video {
//       .video-btns {
//         form {
//           .form-group {
//             @media screen and (min-width: 1024px) {
//               margin-top: 12rem;
//             }
//           }
//         }
//       }
//     }
//   }
// }
// .tile .promotion > .is-media-background-base.is-media-background-inactive .task-container.carousel {
//   // position: relative;
//   @media screen and (min-width: 1024px) {
//     width: 50%;
//     position: absolute;
//     top: 50%;
//     right: 0;
//     overflow: inherit;

//     .task-slide-container {
//       form {
//         .form-group {
//           margin-top: 0;
//           text-align: left;
//         }
//       }
//     }
//   }
// }

a.back,
.close {
  @media screen and (min-width: 1024px) {
    position: absolute;
    right: 0;
  }
}
