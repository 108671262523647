$promotion-content-width: 374px;

$promotion-media-aspect-ratio: percentage(calc(9/16)); //9:16 = percentage(9/16);
$promotion-media-transition-duration: 300ms;
$promotion-media-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

$focus-box-shadow: none;


@import '../../../app/q360-comps/promotion/src/promotion.scss';

@import '../../../app/q360-comps/banner-promotion/src/banner-promotion.scss';

@import '../../../app/q360-comps/single-block-promotion/src/single-block-promotion.scss';

@import '../../../app/q360-comps/news-communications-block-promotion/src/news-communications-block-promotion.scss';

$board-tile-description-color: #000000;
$board-tile-description-font-family: inherit;
$board-tile-description-font-size: 15px;
$board-tile-description-font-weight: normal;
$board-tile-description-line-height: inherit;
$board-tile-description-padding: 0px;
$board-tile-description-margin: 20px 0px 0px 0px;

$board-tile-status-color: #000000;
$board-tile-status-font-family: inherit;
$board-tile-status-font-size: 48px;
$board-tile-status-font-weight: bold;
$board-tile-status-line-height: inherit;
$board-tile-status-padding: 0px;
$board-tile-status-margin: 20px 0px 20px 0px;

$board-tile-complete-color: #000000;
$board-tile-complete-font-family: inherit;
$board-tile-complete-font-size: 13px;
$board-tile-complete-font-weight: normal;
$board-tile-complete-line-height: inherit;
$board-tile-complete-padding: 0px;
$board-tile-complete-margin: 0px 0px 10px 0px;

$board-color: black;
$board-background-color: white;
$board-padding: 10px 0px 20px 20px;

$board-header-color: $board-color;
$board-header-font-family: inherit;
$board-header-font-size: 30px;
$board-header-font-weight: bold;
$board-header-line-height: inherit;
$board-header-padding: 20px 20px 0px 20px;
$board-header-margin: 0px 0px 5px 0px;

$board-text-color: $board-color;
$board-text-font-family: inherit;
$board-text-font-size: 15px;
$board-text-font-weight: normal;
$board-text-line-height: inherit;
$board-text-padding: 0px 20px 0px 20px;
$board-text-margin: 0px;

$board-status-color: $board-color;
$board-status-font-family: inherit;
$board-status-font-size: 18px;
$board-status-font-weight: normal;
$board-status-line-height: inherit;
$board-status-padding: 0px 20px 0px 20px;
$board-status-margin: 20px 0px 0px 0px;

$board-status-out-of-color: gray;
$board-status-out-of-font-family: inherit;
$board-status-out-of-font-size: 18px;
$board-status-out-of-font-weight: bold;
$board-status-out-of-line-height: inherit;
$board-status-out-of-padding: 0px;
$board-status-out-of-margin: 20px 0px 0px 0px;

$board-media-margin: 0px 0px 10px 0px;
$board-blocks-margin: -5px -25px -5px -5px;

$block-color: #ededee;
$block-background-color: #b6b6b6;
$block-border-color: $block-background-color;
$block-border-width: 5px;
$block-border-style: solid;
$block-border-radius: 0px;
$block-font-family: inherit;
$block-font-size: 16px;
$block-font-weight: normal;
$block-line-height: inherit;
$block-height: 108px;
$block-width: calc(33% - 14px);
$block-padding: 40px 12px 12px 12px;
$block-padding-mobile: 10px;
$block-margin: 0;

$block-status-color: white;
$block-status-background-color: #888888;
$block-status-border-style: none;
$block-status-border-color: transparent;
$block-status-border-width: 0px;
$block-status-border-radius: 13px;
$block-status-padding: 2px;
$block-status-margin: 5px;
$block-status-margin-mobile: 0px 0px 0px 10px;
$block-status-width: 26px;
$block-status-height: 26px;

$block-icon-color: white;
$block-icon-background-color: #292929;
$block-icon-font-family: inherit;
$block-icon-font-size: 28px;
$block-icon-font-weight: inherit;
$block-icon-line-height: 46px;
$block-icon-width: 46px;
$block-icon-height: 46px;
$block-icon-border-style: none;
$block-icon-border-width: 0px;
$block-icon-border-color: transparent;
$block-icon-border-radius: 23px;
$block-icon-padding: 0px;
$block-icon-margin: 0px 7px 0px 0px;

$block-active-border-color: transparent;
$block-active-color: inherit;
$block-active-background-color: inherit;
$block-active-status-color: inherit;
$block-active-status-background-color: inherit;
$block-active-status-border-color: #ffffff;
$block-active-icon-color: $block-icon-color;
$block-active-icon-background-color: inherit;
$block-active-icon-border-color: inherit;

$block-complete-color: #fefefe;
$block-complete-background-color: #7f7f7f;
$block-complete-border-color: $block-complete-background-color;
$block-complete-status-color: #ffffff;
$block-complete-status-background-color: #595959;
$block-complete-status-border-color: #ffffff;
$block-complete-icon-color: $block-icon-color;
$block-complete-icon-background-color: $block-icon-background-color;
$block-complete-icon-border-color: $block-complete-icon-background-color;

$block-unlocked-color: #fefefe;
$block-unlocked-background-color: #b6b6b6;
$block-unlocked-border-color: $block-unlocked-background-color;
$block-unlocked-status-color: #ffffff;
$block-unlocked-status-background-color: #878787;
$block-unlocked-status-border-color: #ffffff;
$block-unlocked-icon-color: $block-icon-color;
$block-unlocked-icon-background-color: $block-icon-background-color;
$block-unlocked-icon-border-color: $block-unlocked-icon-background-color;

$block-locked-color: #f0f0f0;
$block-locked-background-color: #dadada;
$block-locked-border-color: $block-locked-background-color;
$block-locked-status-color: #ffffff;
$block-locked-status-background-color: #a9a9a9;
$block-locked-status-border-color: #ffffff;
$block-locked-icon-color: #ffffff;
$block-locked-icon-background-color: #b3b3b3;
$block-locked-icon-border-color: $block-locked-icon-background-color;

$block-transition-duration: 300ms;
$block-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

$board-task-padding: 10px 20px 20px 20px;
$board-task-padding-mobile: 10px 20px 20px 20px;

$block-back-color: #000000 !default;
$block-back-font-family: inherit !default;
$block-back-font-size: 18px !default;
$block-back-font-weight: bold !default;
$block-back-line-height: inherit !default;
$block-back-margin: 0px 0px 20px 0px !default;

@import '../../../app/q360-comps/board-with-block-promotion/src/board-with-block-promotion.scss';

.tile.large.standalone {
  .promotion.board_with_block {
    & > .overlay-container {
      .overlay {
        @media screen and (max-width: 1023px) {
          width: auto;
        }
      }
    }
    .content {
      background-color: $white;
    }
  }
}

.tile.large .task.file .form-group.submit {
  text-align: center;
}

.tile.large .form-group.submit button span:after,
.tile.large .form-group.submit .button span:after,
.tile.large .form-group.submit button span:before,
.tile.large .form-group.submit .button span:before {
  display: none; // Hide the next carets in the button
}

.promotion {
  display: flex;
  justify-content: center;
  .cropping-window-challenge {
    display: flex;

    & > img {
      max-height: 320px;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      object-position: 0 0;
      width: 1920px !important;
      height: 892px !important;

      @media screen and (min-width: 768px) {
        max-height: 430px;
      }

      @media screen and (min-width: 1024px) {
        max-height: 580px;
        width: 100% !important;
      }
    }
  }

  .task {
    p a {
      // hide from visual browsers, but available for screen readers
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
  }

  .form-group {
    &.submit {
      border-top: none;
      padding-top: 0;
      position: relative;
      z-index: 1;
      @media screen and (max-width: 1023px) {
        text-align: center;
      }
    }
  }
  .board_with_block {
    @media screen and (max-width: 1023px) {
      button {
        width: 80%;
      }
    }
  }
}

.board_with_block {
  background-color: $very-light-grey;
  width: 100%;
}

$takover-promotion-content-width: $promotion-content-width;
$takover-promotion-content-margin: 40px;

$takeover-promotion-task-color: white;
$takeover-promotion-task-header-color: white;
$takeover-promotion-task-expiration-color: $takeover-promotion-task-color;
$takeover-promotion-task-error-color: $takeover-promotion-task-color;

$takeover-promotion-task-read-more-color: $takeover-promotion-task-color;
$takeover-promotion-task-link-button-color: $takeover-promotion-task-color;

$takeover-promotion-task-label-color: $takeover-promotion-task-color;

@import '../../../app/q360-comps/takeover-promotion/src/takeover-promotion.scss';
