.tile {
  .promotion {
    &.banner {
      .media {
        & > div {
          padding-bottom: 0px;

          & > * {
            position: static;
          }
        }
      }
    }
  }
}

.BannerPromotion {
  z-index: 1;
  pointer-events: none;
  cursor: pointer;
  &-icon {
    display: block;
    width: 100%;
    height: auto;
    &Container {
      width: 150px;
      @media screen and (max-width: 425px) {
        width: 25px;
      }
    }
  }
  &-overlayContainer {
    position: absolute;
    display: flex;
    align-items: center;
    color: white;
    @media screen and (max-width: 425px) {
      /*
      Due to reusing some css in getHtmlForSmallTile we're running into
      issues with specificity when attempting to change the top prop
      in this media query. See: getHtmlForSmallTile:61
      */
      align-items: unset;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
    max-width: 700px;
    padding: 0 0 0 50px;
    @media screen and (max-width: 425px) {
      padding: 30px 0 0 0;
    }
  }
  &-title {
    font-weight: 400;
    @media screen and (min-width: 426px) {
      font-size: 42px;
    }
  }
}
