.site-content .content-wrapper .content.invoice-upload {
  .form-group.input-file {
    p.error.invalid {
      display: block;
      margin-top: 10px;
    }

    .file-input {
      $file-input-border-color: #BBBBBB;

      background: white;
      border: solid;
      border-color: $file-input-border-color;
      border-width: 1px;
      border-radius: 12px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 338px;
      margin-bottom: $input-margin-y;
      gap: 10px;
      transition: all 0.5s ease;
      position: relative;

      .input-container,
      .file {
        opacity: 1;
        transition: opacity 0.3s;
      }

      &.is-dragging {
        border-color: darken($file-input-border-color, 30%);
        background-color: $very-light-grey;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);

        .input-container,
        .file {
          opacity: 0;
        }

        .droppable-indicator {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
        }
      }

      & > label.file {
        flex-shrink: 0;
        margin-left: 1px;
        margin-right: 1px;
        text-align: center;
        transition: opacity 0.3s;
      }

      & > span {
        display: inline-block;
        width: $image-input-width;
        text-align: center;
      }

      & > .input-container {
        overflow: hidden;

        span {
          display: block;
          height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
        }
      }

      p.error {
        width: $image-input-width;
        margin-left: 1px;
        margin-right: 1px;
        margin-bottom: 10px;
      }
    }
  }
  .form-group.submit {
    text-align: left;
    border-top: none;
    padding: 15px 0px;

    .button{
      width: 129px;
    }
  }
}
