$focus-color: #5b9dd9 !default;
$focus-outline: none !default;
$focus-box-shadow: 0 0 3px 1px $focus-color !default;

$task-color: black !default;
$task-background-color: white !default;
$task-font-family: inherit !default;
$task-font-size: 15px !default;
$task-font-weight: normal !default;
$task-line-height: inherit !default;
$task-margin: 20px !default;

$task-header-color: black !default;
$task-header-font-family: $task-font-family !default;
$task-header-font-size: 30px !default;
$task-header-font-weight: normal !default;
$task-header-line-height: inherit !default;
$task-header-margin: 0px !default;

$task-text-color: $task-color !default;
$task-text-font-family: $task-font-family !default;
$task-text-font-size: $task-font-size !default;
$task-text-font-weight: $task-font-weight !default;
$task-text-line-height: $task-line-height !default;
$task-text-margin: 10px 0px 10px 0px !default;

$task-expiration-color: #bfbfbf !default;
$task-expiration-font-family: $task-font-family !default;
$task-expiration-font-size: $task-font-size !default;
$task-expiration-font-weight: $task-font-weight !default;
$task-expiration-line-height: $task-line-height !default;
$task-expiration-margin: 2px 0px 0px 0px;

$task-complete-color: #858585 !default;
$task-complete-font-family: $task-font-family !default;
$task-complete-font-size: 18px !default;
$task-complete-font-weight: bold !default;
$task-complete-line-height: 1 !default;
$task-complete-margin: 10px 0px 0px 0px !default;

$task-complete-date-color: #858585 !default;
$task-complete-date-font-family: $task-font-family !default;
$task-complete-date-font-size: 9px !default;
$task-complete-date-font-weight: lighter !default;
$task-complete-date-line-height: 1 !default;
$task-complete-date-margin: 0px 0px 0px 0px !default;

$task-error-color: red !default;
$task-error-font-family: $task-font-family !default;
$task-error-font-size: $task-font-size !default;
$task-error-font-weight: $task-font-weight !default;
$task-error-line-height: $task-line-height !default;
$task-error-margin: 0px 0px 3px 0px !default;

$task-read-more-color: #bfbfbf !default;
$task-read-more-font-family: $task-font-family !default;
$task-read-more-font-size: 18px !default;
$task-read-more-font-weight: bold !default;
$task-read-more-line-height: inherit !default;
$task-read-more-margin: 0px 0px 5px 0px !default;

$task-link-button-color: #bfbfbf !default;
$task-link-button-font-family: $task-font-family !default;
$task-link-button-font-size: 18px !default;
$task-link-button-font-weight: bold !default;
$task-link-button-line-height: inherit !default;

$task-page-color: white !default;
$task-page-active-color: white !default;
$task-page-complete-color: white !default;
$task-page-complete-active-color: white !default;
$task-page-background-color: #b5b5b5 !default;
$task-page-active-background-color: black !default;
$task-page-complete-background-color: #00b000 !default;
$task-page-complete-active-background-color: #006000 !default;
$task-page-border: 0px solid white !default;
$task-page-border-radius: 16px !default;
$task-page-active-border-color: white !default;
$task-page-complete-border-color: white !default;
$task-page-complete-active-border-color: white !default;

$task-page-height: 26px !default;
$task-page-font-family: $task-font-family !default;
$task-page-font-size: 15px !default;
$task-page-font-weight: normal !default;
$task-page-line-height: $task-page-height !default;
$task-page-margin: 0px 2.5px !default;

$task-slide-duration: 300ms !default;
$task-slide-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

$task-error-slide-duration: 300ms !default;
$task-error-slide-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

$task-label-color: black !default;
$task-label-font-family: inherit !default;
$task-label-font-size: 15px !default;
$task-label-font-weight: bold !default;
$task-label-line-height: inherit !default;
$task-label-padding: 0px !default;
$task-label-margin: 0px 0px 10px 0px !default;

$task-input-color: black !default;
$task-input-color-placeholder: #999 !default;
$task-input-background-color: white !default;
$task-input-background-color-disabled: #e0e0e0 !default;
$task-input-border-size: 1px !default;
$task-input-border-color: #c0c0c0 !default;
$task-input-border-radius: 0px !default;
$task-input-font-family: inherit !default;
$task-input-font-size: 20px !default;
$task-input-font-weight: normal !default;
$task-input-line-height: inherit !default;
$task-input-height: 36px !default;
$task-input-padding: 4px 8px !default;
$task-input-margin: 0px 0px 10px 0px !default;

.task-container {
  position: relative;
  color: $task-color;
  background-color: $task-background-color;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $task-margin;
  width: 100%;

  &.carousel {
    padding: 0px;
  }

  .task-slide-container {
    display: flex;
    transition: $task-slide-duration $task-slide-easing left;

    &.user-slide {
      transition: none;
    }

    .task-slide {
      width: 100%;
      display: inline-block;
      padding: $task-margin;
      box-sizing: border-box;
    }

    &.error {
      margin-top: auto;
      align-items: flex-start;

      .task-slide {
        padding: $task-margin 0px 0px 0px;
      }
    }
  }

  ul.task-pager {
    list-style: none;
    margin: 0px 0px auto 0px;
    padding: 0px;
    text-align: center;

    li {
      display: inline-block;
      height: $task-page-height;
      width: $task-page-height;
      text-align: center;
      padding: 0px;
      margin: $task-page-margin;
      font-family: $task-page-font-family;
      font-size: $task-page-font-size;
      font-weight: $task-page-font-weight;
      line-height: $task-page-line-height;
      vertical-align: middle;

      a {
        text-decoration: none;
        display: block;
        cursor: pointer;
        border: $task-page-border;
        border-radius: $task-page-border-radius;
        color: $task-page-color;
        font-family: $task-font-family;
        font-size: $task-page-font-size;
        font-weight: $task-page-font-weight;
        line-height: $task-page-line-height;
        background-color: $task-page-background-color;
        width: 100%;

        &:focus {
          outline: $focus-outline;
          box-shadow: $focus-box-shadow;
        }
      }

      &.active {
        a {
          color: $task-page-active-color;
          background-color: $task-page-active-background-color;
          border-color: $task-page-active-border-color;
        }
      }

      &.complete {
        a {
          color: $task-page-complete-color;
          background-color: $task-page-complete-background-color;
          border-color: $task-page-complete-border-color;
        }

        &.active {
          a {
            color: $task-page-complete-active-color;
            background-color: $task-page-complete-active-background-color;
            border-color: $task-page-complete-active-border-color;
          }
        }
      }
    }
  }

  .task-animate-enter {
    transition: all $task-slide-duration $task-slide-easing;
  }

  .task-animate-exit {
    transition: all $task-slide-duration $task-slide-easing;
  }
}

.task {
  color: $task-color;
  background-color: $task-background-color;
  font-family: $task-font-family;
  font-size: $task-font-size;
  font-weight: $task-font-weight;
  line-height: $task-line-height;

  .title {
    color: $task-header-color;
    font-family: $task-header-font-family;
    // font-size: $task-header-font-size;
    font-weight: $task-header-font-weight;
    line-height: $task-header-line-height;
    margin: $task-header-margin;
    line-height: 1.2;

    // Standard Font size
    font-size: 42px;
    @media screen and (max-width: 768px) {
      font-size: 21px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 34px;
    }
  }

  div {
    &.text {
      font-family: $task-text-font-family;
      font-size: $task-text-font-size;
      font-weight: $task-text-font-weight;
      line-height: $task-text-line-height;
      margin: $task-text-margin;

      img {
        max-width: 80%;
        height: auto;
      }
    }

    &.completed-text {
      margin-bottom: 0px;
    }
  }

  p {
    font-family: $task-font-family;
    font-size: $task-font-size;
    font-weight: $task-font-weight;
    line-height: $task-line-height;
    margin: 0px;

    &.expires {
      color: $task-expiration-color;
      font-family: $task-expiration-font-family;
      font-size: $task-expiration-font-size;
      font-weight: $task-expiration-font-weight;
      line-height: $task-expiration-line-height;
      margin: $task-expiration-margin;
      text-align: center;
    }

    &.complete {
      display: inline-block;
      text-align: center;

      span:first-child {
        display: block;
        color: $task-complete-color;
        font-family: $task-complete-font-family;
        font-size: $task-complete-font-size;
        font-weight: $task-complete-font-weight;
        line-height: $task-complete-line-height;
        margin: $task-complete-margin;
      }

      span {
        color: $task-complete-date-color;
        font-family: $task-complete-date-font-family;
        font-size: $task-complete-date-font-size;
        font-weight: $task-complete-date-font-weight;
        line-height: $task-complete-date-line-height;
        margin: $task-complete-date-margin;
      }
    }
  }

  .read-more {
    display: inline-block;
    cursor: pointer;
    font-family: $task-read-more-font-family;
    font-size: $task-read-more-font-size;
    font-weight: $task-read-more-font-weight;
    line-height: $task-read-more-line-height;
    text-decoration: none;
    color: $task-read-more-color;
    margin: $task-read-more-margin;

    &:focus {
      outline: $focus-outline;
      box-shadow: $focus-box-shadow;
    }

    span {
      position: relative;
      padding-right: 15px;
    }

    span:before {
      display: inline-block;
      content: '';
      width: 4px;
      height: 4px;
      border-right: $task-read-more-color 2px solid;
      border-bottom: $task-read-more-color 2px solid;
      transform: rotate(-45deg);
      position: absolute;
      right: 6px;
      top: 50%;
      margin-top: -2px;
    }

    span:after {
      display: inline-block;
      content: '';
      width: 4px;
      height: 4px;
      border-right: $task-read-more-color 2px solid;
      border-bottom: $task-read-more-color 2px solid;
      transform: rotate(-45deg);
      position: absolute;
      right: 1px;
      top: 50%;
      margin-top: -2px;
    }
  }

  .form-group {
    label {
      display: block;
      color: $task-label-color;
      font-family: $task-label-font-family;
      font-size: $task-label-font-size;
      font-weight: $task-label-font-weight;
      line-height: $task-label-line-height;
      padding: $task-label-padding;
      margin: $task-label-margin;
    }

    fieldset {
      border: none;
      padding: 0px;
      margin: 0px;

      legend {
        display: block;
        color: $task-label-color;
        font-family: $task-label-font-family;
        font-size: $task-label-font-size;
        font-weight: $task-label-font-weight;
        line-height: $task-label-line-height;
        padding: $task-label-padding;
        margin: $task-label-margin;
      }
    }

    // input {
    //   box-sizing: border-box;
    //   color: $task-input-color;
    //   background-color: $task-input-background-color;
    //   border: solid $task-input-border-size $task-input-border-color;
    //   border-radius: $task-input-border-radius;
    //   font-family: $task-input-font-family;
    //   font-size: $task-input-font-size;
    //   font-weight: $task-input-font-weight;
    //   line-height: $task-input-line-height;
    //   width: 100%;
    //   height: $task-input-height;
    //   padding: $task-input-padding;
    //   margin: $task-input-margin;

    //   &::placeholder {
    //     color: $task-input-color-placeholder;
    //   }
    // }

    textarea {
      box-sizing: border-box;
      display: block;
      resize: none;
      color: $task-input-color;
      background-color: $task-input-background-color;
      border: $task-input-border-size solid $task-input-border-color;
      font-family: $task-input-font-family;
      font-size: $task-input-font-size;
      font-weight: $task-input-font-weight;
      line-height: $task-input-line-height;
      padding: $task-input-padding;
      margin: $task-input-margin;
      width: 100%;

      &::placeholder {
        color: $task-input-color-placeholder;
      }
    }
  }
}

.error-carousel {
  position: relative;
  display: flex;
  align-items: flex-start;

  & > button.previous {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 3px;
    margin-right: 5px;
    border: none;
    background: transparent;
    cursor: pointer;

    span:after {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border-left: $task-error-color 2px solid;
      border-bottom: $task-error-color 2px solid;
      transform: rotate(45deg);
      position: relative;
      left: 1px;
      top: 1px;
    }
  }

  & > .error-container {
    display: inline-block;
    flex: 1;
    text-align: center;
  }

  & > button.next {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 3px;
    border: none;
    background: transparent;
    cursor: pointer;

    span:after {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border-right: $task-error-color 2px solid;
      border-bottom: $task-error-color 2px solid;
      transform: rotate(-45deg);
      position: relative;
      left: -3px;
      top: 1px;
    }
  }
}

.error-container {
  p {
    &.error {
      color: $task-error-color;
      font-family: $task-error-font-family;
      font-size: $task-error-font-size;
      font-weight: $task-error-font-weight;
      line-height: $task-error-line-height;
      margin: $task-error-margin;
      text-align: center;
    }
  }

  .error-animate-enter {
    transition: $task-error-slide-duration $task-error-slide-easing all;
  }

  .error-animate-exit {
    transition: $task-error-slide-duration $task-error-slide-easing all;
  }

  &.before {
    .error-animate-enter {
      position: absolute;
      left: 25px;
      right: 25px;
      opacity: 0;
      top: 100%;
    }

    .error-animate-enter.error-animate-enter-active {
      opacity: 1;
      top: 0;
    }

    .error-animate-exit {
      opacity: 0;
    }

    .error-animate-exit.error-animate-exit-active {
    }
  }

  &.after {
    .error-animate-enter {
      position: absolute;
      left: 25px;
      right: 25px;
      opacity: 0;
      top: -100%;
    }

    .error-animate-enter.error-animate-enter-active {
      opacity: 1;
      top: 0;
    }

    .error-animate-exit {
      opacity: 0;
    }

    .error-animate-exit.error-animate-exit-active {
    }
  }
}
