.content {
  &.login {
    .form-group {
      .forgot-username,
      .forgot-password {
        padding: 5px 0px 0px 0px;
        text-align: right;

        a {
          color: black;
          font-weight: bold;
          margin: 5px 0px 0px 0px;
        }
      }
    }
  }
}
