$table-cell-padding-x: 5px;
$table-cell-padding-y: 7px;
$table-head-color: black;

$table-cell-vertical-alignments: ('middle', 'bottom', 'top');
$default-table-cell-vertical-alignment: 'top';

.ReactTable {
  position: relative;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    line-height: 1.4;

    thead {
      margin: 10px 0;
      color: $table-head-color;

      tr {
        border-bottom: 1px solid #d1d1d1;

        th {
          text-align: left;
          font-weight: normal;
          padding: $table-cell-padding-y $table-cell-padding-x;

          &:has(button) {
            padding: 0;
          }

          button {
            position: relative;
            cursor: pointer;
            border: none;
            background: none;
            padding: $table-cell-padding-y 30px $table-cell-padding-y $table-cell-padding-x;
            text-align: left;
            color: $table-head-color;

            &::after {
              content: '';
              display: block;
              position: absolute;
              right: 10px;
              top: 14px;
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
            }
          }

          &[aria-sort='ascending'] {
            button::after {
              border-bottom: 6px solid $table-head-color;
            }
          }

          &[aria-sort='descending'] {
            button::after {
              border-top: 6px solid $table-head-color;
            }
          }

          &[aria-sort='none'] {
            button::after {
              border-bottom: 6px solid $light-grey;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: $table-cell-padding-y $table-cell-padding-x;
          vertical-align: $default-table-cell-vertical-alignment;

          .button {
            margin: 0;
            font-size: 12px;
          }
        }

        &:first-child {
          td {
            padding-top: $table-cell-padding-y + 10px;
          }
        }
      }
    }
  }

  @each $value in $table-cell-vertical-alignments {
    // No need to define class for default alignment
    @if $value != $default-table-cell-vertical-alignment {
      &.cell-vertical-align-#{$value} {
        table {
          tbody {
            tr {
              td {
                vertical-align: #{$value};
              }
            }
          }
        }
      }
    }
  }

  @each $breakpoint in map-keys($screen-breakpoints) {
    &.stacked-#{$breakpoint} {
      $nextBreakpoint: breakpoint-next($breakpoint);
      @if $nextBreakpoint {
        @include media-breakpoint-down($nextBreakpoint) {
          table {
            thead {
              display: none;
            }

            tbody {
              tr {
                display: block;
                border-bottom: 2px solid $light-grey3;
                padding: 20px 0;

                &:first-child {
                  padding-top: 0;

                  td {
                    padding-top: $table-cell-padding-y;
                  }
                }

                &:last-child {
                  border-bottom: none;
                }

                td {
                  display: flex;

                  span {
                    width: 60%;
                  }

                  &::before {
                    content: attr(data-header);
                    display: inline-block;
                    width: 40%;
                    text-align: right;
                    color: black;
                    font-weight: bold;
                    padding-right: 10px;
                  }

                  // Special handling to align buttons in columns with no header
                  &[data-header=""]:has(.button) {
                    span {
                      display: flex;
                      width: 100%;
                      justify-content: center;

                      .button {
                        width: auto;
                      }
                    }

                    &::before {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable .rt-thead.-header {
  border-bottom: 1px solid #d1d1d1;
  margin: 10px 0;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  color: #000;
  //transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 10px;
    top: 16px;
    width: 0px;
    height: 0px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid black;
  }
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 10px;
    top: 16px;
    width: 0px;
    height: 0px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid black;
  }
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody {
  display: flex;
  flex-direction: column;
}
.ReactTable .rt-tr-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.ReactTable .rt-tr {
  display: inline-flex;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  //transition: 0.3s ease;
  //transition-property: width, min-width, padding, opacity;
}
.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)' !important;
  filter: alpha(opacity=0) !important;
}
.ReactTable .rt-expander-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ReactTable .rt-expander {
  width: 0;
  height: 0;
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  //transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: rotate(-90deg);
}
.ReactTable .rt-expander.-open {
  transform: rotate(0);
}
.ReactTable.-striped .rt-tr-group:nth-child(even) {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tr-group:hover {
  //background: rgba(0, 0, 0, 0.05)
}
.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  margin-top: 16px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  //transition: all 0.1s ease;
  cursor: pointer;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
  filter: alpha(opacity=50);
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  //background: rgba(0, 0, 0, 0.3);
  //color: #fff
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
  margin: 0 3px 0 6px;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageInfo .-totalPages {
  display: inline-block;
  margin: 0 3px;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .-pagination .-of {
  display: inline-block;
  margin: 0 3px;
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  //transition: all 0.3s ease;
  z-index: 2;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  //transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
}
.ReactTable .-loading.-active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}
.ReactTable input,
.ReactTable select {
  appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
}
.ReactTable .select-wrap {
  position: relative;
  display: inline-block;
}
.ReactTable .select-wrap select {
  padding: 5px 15px 5px 7px;
  min-width: 100px;
}
.ReactTable .select-wrap:after {
  content: '';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ReactTable .-padRow {
  display: none;
}
