.content {
  &.welcome {
    @include content-container-fluid;

    .content-header {
      font-size: 42px;
    }

    .content-description {
      margin-bottom: 30px;
    }

    .carousel {
      .slide-container {
        .slide {
          background: transparent;

          & > div {
            width: 100%;
            overflow: hidden;

            h1 {
              color: black;
              font-family: inherit;
              font-size: 30px;
              font-weight: bold;
              margin: 0;

              span.step {
                color: gray;
              }
            }

            p {
              margin: 0px 0px 5px 0px;
            }

            video,
            image,
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      ul.pager {
        display: none;
      }
    }

    .form-group {
      &.submit {
        text-align: center;
        border-top: none !important;
        padding: 0;

        button,
        .button {
          margin: 0px 5px;
          width: 172px;
          margin: 0px 12px 10px 0px;
        }
      }
    }
  }
}
