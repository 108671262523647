//*******************************************************************************************/
//************** variables/buttons.scss - the starting file for global-defaults ****************/
//*******************************************************************************************/

$button-color: $white;
$button-color-hover: $brand-primary-color;
$button-color-disabled: $white;
$button-background-color: $brand-primary-color;
$button-background-color-hover: transparent;
$button-background-color-disabled: $brand-grey;
$button-border-width: 2px;
$button-border: $button-border-width solid $brand-primary-color;
$button-border-disabled: $button-border-width solid $button-background-color-disabled;
$button-border-hover: $button-border;
$button-border-radius: 0;
$button-box-shadow: none;
$button-font-family: $branded-font-stack;
$button-font-size: 16px;
$button-font-weight: 700;
$button-height: 45px;
$button-padding-x: 1.5rem;
$button-padding-y: 0.625rem;
$button-padding: $button-padding-y $button-padding-x;
$button-margin: 20px auto;
$button-text-align: center;
$button-text-transform: uppercase;

$button-secondary-color: $brand-primary-color;
$button-secondary-color-hover: $white;
$button-secondary-color-disabled: $button-color-disabled;
$button-secondary-background-color: transparent;
$button-secondary-background-color-hover: $brand-primary-color;
$button-secondary-background-color-disabled: $button-background-color-disabled;
$button-secondary-border: $button-border-width solid $brand-primary-color;
$button-secondary-border-hover: $button-border-width solid $brand-primary-color;
$button-secondary-border-disabled: $button-border-width solid $button-border-disabled;
