$radial-meter-size: 274;

$radial-meter-bar-width: 22px;
$radial-meter-bar-shift: -2;
$radial-meter-overflow-width: 9px;
$radial-meter-overflow-shift: 13;
$radial-meter-trail-width: 35px;

$radial-meter-bar-color: #ef9021;
$radial-meter-overflow-color: #da2727;
$radial-meter-overlay-color: #2c2c2c;
$radial-meter-trail-color: #525252;

$radial-meter-font-size: 51px;
$radial-meter-font-color: #fff;

$progress-meter-size: 274;

$progress-meter-bar-width: 22px;
$progress-meter-bar-shift: -2;
$progress-meter-overflow-width: 9px;
$progress-meter-overflow-shift: 13;
$progress-meter-trail-width: 35px;

$progress-meter-bar-color: #ef9021;
$progress-meter-overflow-color: #da2727;
$progress-meter-overlay-color: #2c2c2c;
$progress-meter-trail-color: #525252;

$progress-meter-font-size: 51px;
$progress-meter-font-color: #fff;

$state-meter-height: 44px;
$state-meter-width: 225px;

$drawer: #fff;
$description: #6e6e6e; //#0ABDFF - blue;
$title: #cb117d;
$btn-color: #cd117d;
$btn-text: #fff;
$gauge-color: #cd117d;
$color: #cd117c9a;
$btn-hover-color: #6e6e6e;

@import '../../../app/q360-comps/radial-meter/src/radial-meter.scss';
@import '../../../app/q360-comps/gauge-meter/src/gauge-meter.scss';

.meter-container {
  @include content-container-fluid;
  /* z-index: 1000; */
  position: relative;
  max-height: 200px;
}

.meter-drawer {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.meter-layout {
  width: 616px;
  height: auto;
  background: $drawer 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 8px #00000029;
  border-radius: 0px 0px 16px 16px;
  opacity: 1;
  position: fixed;
  z-index: 3;

  @media screen and (max-width: 480px) {
    width: 100%;
    //z-index: 999; //Removed for nav menu bar overlapping issue
    left: 0px;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    //background: linear-gradient(180deg, rgba(10,189,255,1) 70%, rgba(10,189,255,0.8) 100%);
    background-color: $drawer;
  }

  &.small {
    height: 55px;

    @media screen and (max-width: 480px) {
      width: 100%;
      height: 28px;
      border-radius: 0px;
      left: 0px;
    }

    .meter {
      margin: 10px 40px 20px 30px;
      height: 55px;

      @media screen and (max-width: 480px) {
        height: 28px;
        margin: -3px 19px;
      }

      .meter-component {
        height: auto;
        margin-bottom: 0px;

        .meter-title {
          width: auto;
          max-width: 30%;
          float: left;

          @media screen and (max-width: 480px) {
            width: auto;
            height: 28px;
            margin-top: -8px;
          }

          @media screen and (max-width: 395px) {
            width: auto;
            height: 28px;
            margin-top: 0px;
          }
        }

        .title {
          width: auto;
          font-family: inherit !important;
          font: normal normal bold 14px/31px adelle-sans;

          @media screen and (max-width: 480px) {
            margin: 5px 0px;
          }

          @media screen and (max-width: 395px) {
            font-family: inherit !important;
            font: normal normal bold 12px adelle-sans;
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }

  .meter {
    top: 110px;
    left: 834px;
    width: calc(90% - 10px);
    height: auto;
    opacity: 1;
    margin: 30px 35px;

    @media screen and (max-width: 480px) {
      margin: 30px 24px;
      //min-height: 345px;
    }

    .meter-component {
      top: 110px;
      left: 834px;
      width: 100%;
      height: 121px;
      opacity: 1;
      margin-bottom: 25px;

      @media screen and (max-width: 480px) {
        height: auto;
      }

      .title {
        top: 110px;
        left: 834px;
        width: 60%;
        //height: 21px;
        text-align: left;
        font-family: inherit !important;
        font: normal normal bold 16px/21px adelle-sans;
        letter-spacing: 0px;
        color: $title;
        opacity: 1;
        //margin-bottom: 8px;
        float: left;

        @media screen and (max-width: 480px) {
          font-family: inherit !important;
          font: normal normal bold 14px adelle-sans;
          margin-top: 2px;
          height: auto;
          width: 100%;
        }
      }

      .meter-display-wrap {
        width: 70%;
        text-align: left;
        font-family: inherit !important;
        font: normal normal 600 14px/19px adelle-sans;
        letter-spacing: 0px;
        color: $description;
        opacity: 1;
        float: left;

        @media screen and (max-width: 480px) {
          font-family: inherit !important;
          font: normal normal 600 14px adelle-sans;
          width: 65%;
          height: auto;
          //margin-bottom: 45px;
        }

        @media screen and (max-width: 395px) {
          font-family: inherit !important;
          font: normal normal 600 12px adelle-sans;
          width: 60%;
          margin-right: 20px;
          height: auto;
          max-height: 70px;
          text-align: left;
        }
      }

      .meter-svg-images {
        display: flex;

        @media screen and (max-width: 480px) {
          width: 30%;
          float: right;
        }
      }

      .svg-padding {
        padding-left: 25px;
        padding-top: 10px;
        margin-bottom: 10px;

        @media screen and (max-width: 480px) {
          padding-left: 26px;
          padding-top: 0px;
          margin-top: -36px;
        }

        @media screen and (max-width: 395px) {
          padding-left: 26px;
          padding-top: 0px;
          margin-top: -36px;
        }
      }

      .radial-meter {
        top: 110px;
        left: 834px;
        width: calc(75% - 10px);
        height: 121px;
        opacity: 1;
      }

      .gauge-meter {
        top: 257px;
        left: 834px;
        width: calc(75% - 10px);
        opacity: 1;

        .gauge-chart {
          width: 180px;
          float: right;
          //margin-top: -35px;

          @media screen and (max-width: 480px) {
            width: 145px;
            float: right;
            //margin-top: -150px !important;
            //margin-right: -20px;
            min-height: 110px;
          }

          .text-group {
            transform: translate(64, 60);
            font-family: inherit !important;
            font: normal normal bold 24px adelle-sans;
          }
        }
      }

      .progress-bar {
        height: auto;

        .title {
          top: 98px;
          left: 831px;
          width: 80px;
          height: 18px;
          text-align: left;
          font-family: inherit !important;
          font: normal normal bold 14px/31px adelle-sans;
          letter-spacing: 0px;
          color: #fff;
          opacity: 1;
        }
      }

      &.none {
        .title {
          margin-left: auto;
          margin-right: auto;
        }

        .meter-display-wrap {
          margin-left: auto;
          margin-right: auto;
        }

        .meter-component {
          display: none;
        }
      }
    }
  }
}

.meter-buttons {
  display: block;
  padding: 0px 34px 24px 34px;

  @media screen and (max-width: 480px) {
    padding: 0px 34px 24px 24px;
    float: left;
    margin-top: 10px;
  }
}

.btn-white {
  background-color: $btn-color;
  padding: 8px;
  width: 126px;
  height: 42px;
  border-radius: 4px;
  text-align: center;
  color: $btn-text;
  cursor: pointer;
  border: #fff;
}

.btn-white:hover {
  background-color: $btn-hover-color;
}

.updown-img {
  float: right;
  cursor: pointer;
  margin-top: 25px;
  width: 50px;

  @media screen and (max-width: 480px) {
    width: 12px;
    margin: 24px 25px;
    margin: 10.6px 11px;
    height: auto;
  }

  @media screen and (max-width: 395px) {
    width: 12px;
    margin: 24px 25px;
    margin: 10.6px 11px;
    height: auto;
  }
}

.meter-component.gauge-meter .needle {
  stroke: #fff;
  stroke-width: 2px;
}

.meter-component .gauge-meter .gauge-chart svg {
  height: 101px !important;
}

.meter-component .gauge-meter .gauge-chart .text-group {
  transform: translate(64, 82) !important;
  font-size: 24px;
  font-family: inherit !important;
  font: normal normal bold 24px adelle-sans;
}

.gauge-clear {
  clear: both;
}

.progressbar-root {
  width: 75%;
  margin-top: 13px;
  float: right;

  @media screen and (max-width: 480px) {
    width: 66%;
    float: right;
    margin: 10px 0px;
    height: auto;
  }

  @media screen and (max-width: 395px) {
    width: 66%;
  }

  .parent {
    width: calc(91% - 10px);
    background: 0% 0% no-repeat padding-box padding-box rgba(203,17,126,0.15);
    border-radius: 40px;
    float: left;

    @media screen and (max-width: 480px) {
      width: calc(84% - 10px);
    }

    @media screen and (max-width: 395px) {
      width: calc(84% - 10px);
    }
  }

  .progresstext {
    padding: 10;
    color: black;
    font-weight: 900;
  }

  .percent-dot {
    top: 101px;
    left: 1195px;
    width: 16px;
    height: 16px;
    background: $btn-color 0% 0% no-repeat padding-box;
    border: 2px solid #0ABDFF;
    opacity: 1;
  }

  .bar-percentage {
    text-align: right;
    margin-top: -7px;
    font-family: inherit !important;
    font: normal normal bold 16px/21px adelle-sans;
    letter-spacing: 0px;
    color: $btn-color;
    opacity: 1;
  }

  .progress-bar-dot:after {
    content: "";
    width: 16px;
    height: 16px;
    background: $btn-color;
    position: absolute;
    border-radius: 500%;
    margin: -5px -10px 0px;
    border: 2px solid $btn-text;
  }
}

.gauge-meter-padding {
  padding-top: 20px;
}

/* use Gauge styles */
.svg-image-p-2 {
  width: 20%;
  display: block;
  overflow: visible;
  float: right;

  @media screen and (max-width: 480px) {
    padding-right:30px;
  }

  @media screen and (max-width: 395px) {
    padding-right:30px;
  }

  .stroke-gray-200 {
    stroke: $gauge-color;
  }

  .stroke-green-400 {
    stroke: $gauge-color;
  }

  .fill-gray-700 {
    fill: $gauge-color;
  }

  .fill-white {
    fill: $gauge-color;
  }

  .fill-gray-300 {
    fill: $gauge-color;
  }

  .percentage-text {
    font-size: 35px;
    padding-top: 30px;
  }
}
