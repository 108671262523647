$focus-color: #5b9dd9 !default;

$modal-overlay-color: rgba(0, 0, 0, 0.75) !default;
$modal-overlay-padding: 147px 60px 60px 60px !default;
$modal-overlay-padding-mobile: 20px 20px 20px 20px !default;
$modal-overlay-margin: 0px !default;
$modal-overlay-margin-mobile: 68px 0px 107px 0px !default;

$modal-color: black !default;
$modal-background-color: white !default;
$modal-padding: 0;
$modal-padding-mobile: 30px 0;
$modal-margin-top: 15px !default;

$modal-close-color: white !default;
$modal-close-background-color: #737373 !default;
$modal-close-border-style: none !default;
$modal-close-border-width: 0px !default;
$modal-close-border-color: transparent !default;
$modal-close-border-radius: 17px !default;
$modal-close-width: 34px !default;
$modal-close-height: 34px !default;
$modal-close-margin: 15px 15px 0px 0px !default;
$modal-close-padding: 0px !default;

$modal-transition-duration: 500ms !default;
$modal-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

@mixin modal-close-button-close-class {
  display: block;
  width: $modal-close-width;
  height: $modal-close-height;
  border-style: $modal-close-border-style;
  border-width: $modal-close-border-width;
  border-color: $modal-close-border-color;
  border-radius: $modal-close-border-radius;
  padding: $modal-close-padding;
  line-height: 1;
  cursor: pointer;
  color: $modal-close-color;
  background-color: $modal-close-background-color;
  position: absolute;
  right: 0px;
  top: 0px;
  margin: $modal-close-margin;
  z-index: 1;

  svg {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    fill: $modal-close-color;
  }
}

.modal-close-button-close-class {
  @include modal-close-button-close-class();
}

.modal-container {
  display: none;

  &.active {
    display: block;
  }

  .modal-overlay {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: $modal-overlay-color;
    padding: $modal-overlay-padding;
    margin: $modal-overlay-margin;
    z-index: 200;

    @media screen and (max-width: 1023px) {
      padding: $modal-overlay-padding-mobile;
      margin: $modal-overlay-margin-mobile;
    }

    .modal {
      position: relative;
      width: 100%;
      height: 100%;
      margin: auto;
      color: $modal-color;
      z-index: 3;

      > svg {
        &.background {
          fill: $modal-background-color;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }

      .close {
        @include modal-close-button-close-class();
      }

      .content-container {
        // overflow is causing double scrollbars to appear
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        .content {
          padding: $modal-padding;

          @media screen and (max-width: 1023px) {
            padding: $modal-padding-mobile;
          }
          @media screen and (min-width: 1024px) {
            height: 100%;
          }

          .block-container {
            @media screen and (min-width: 1024px) {
              height: 100%;
              div.bwb-content {
                height: 100%;
              }
            }
          }

          .block-containers-block-header .media img {
            width: 100%;
          }
        }
      }
    }
  }
}

.attach-with-header {
  margin-top: $modal-margin-top;
}

.modal-container {
  .modal-overlay {
    transition: $modal-transition-duration $modal-transition-easing all;

    &.modal-animate-enter {
      opacity: 0;
    }

    &.modal-animate-enter.modal-animate-enter-active {
      opacity: 1;
    }

    &.modal-animate-exit {
      opacity: 1;
    }

    &.modal-animate-exit.modal-animate-exit-active {
      opacity: 0;
    }
  }
}

.modal-container {
  &.scale {
    .modal-animate-enter {
      .modal {
        visibility: hidden;
        transform: translateY(60%) scale(0);
        transition: transform $modal-transition-duration, visibility 0s $modal-transition-duration;
      }
    }

    .modal-animate-enter.modal-animate-enter-active {
      .modal {
        visibility: visible;
        transform: translateY(0%) scale(1);
        transition: transform $modal-transition-duration;
      }
    }

    .modal-animate-exit {
      .modal {
        transition: transform $modal-transition-duration;
      }
    }

    .modal-animate-exit.modal-animate-exit-active {
      .modal {
        visibility: hidden;
        transform: translateY(60%) scale(0);
        transition: transform $modal-transition-duration, visibility 0s $modal-transition-duration;
      }
    }
  }
}

.modal-container {
  &.genie {
    .modal-animate-enter {
      .modal {
        .content-container,
        button.close {
          opacity: 0;
          transform: translateY(60%) scale(0);
          transition: transform $modal-transition-duration, visibility 0s $modal-transition-duration;
        }
      }
    }

    .modal-animate-enter.modal-animate-enter-active {
      .modal {
        .content-container,
        button.close {
          opacity: 1;
        }
      }
    }

    .modal-animate-exit {
      .modal {
        .content-container,
        button.close {
          opacity: 1;
        }
      }
    }

    .modal-animate-exit.modal-animate-exit-active {
      .modal {
        .content-container,
        button.close {
          opacity: 0;
          transform: translateY(60%) scale(0);
          transition: transform $modal-transition-duration, visibility 0s $modal-transition-duration;
        }
      }
    }
  }
}

.modal-container {
  &.slide {
    .modal-animate-enter {
      .modal {
        // visibility: hidden;
        // transform: translateX(60%);
        // transition: transform $modal-transition-duration, visibility 0s $modal-transition-duration;
        opacity: 0;
      }
    }

    .modal-animate-exit {
      .modal {
        opacity: 0;
        // transition: transform $modal-transition-duration;
      }
    }

    .modal-animate-enter.modal-animate-enter-active {
      .modal {
        opacity: 0.5;
        // visibility: visible;
        // transform: translateX(0%);
        // transition: transform $modal-transition-duration;
      }
    }

    .modal-animate-exit.modal-animate-exit-active {
      .modal {
        opacity: 0;
        // visibility: hidden;
        // transform: translateX(60%);
        // transition: transform $modal-transition-duration, visibility 0s $modal-transition-duration;
      }
    }
  }
}
