//*******************************************************************************************/
//*************** pages/main.scss - the starting file for global-defaults *******************/
//*******************************************************************************************/
$grid-gap: 20px;

.take-quiz {
  .secondary-btn {
    background-color: $white;
    border: none;
  }
}

.main-page {
  .welcome-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: $grid-gap;
    @include content-container;

    @include media-breakpoint-up('md') {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up('xl') {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .ragged-layout {
      @include media-breakpoint-up('md') {
        order: 1;
        grid-column: span 2;
      }

      @include media-breakpoint-up('xl') {
        margin-right: calc(43px - $grid-gap);
      }

      .item-container {
        position: relative;

        .tile.large {
          .board-container-main {
            width: inherit;
            margin: 0;
          }

          .cropping-window-challenge{
            background-color: $brand-secondary-color;
            aspect-ratio: 811 / 416;

            img {
              max-height: 416px;
              object-fit: cover;
              object-position: center;
              width: 100% !important;
              height: auto !important;

              @media screen and (min-width: 1024px) {
                height: 580px !important;
              }
            }
          }

          .block-content.tile-content {
            flex-direction: column;
            top: 40%;
          }

          @media screen and (min-width: 1921px) {
            width: inherit;
            margin: unset;
          }
        }
      }
    }

    .user-info {
      background-color: $white;
      padding: 30px 30px 20px 30px;

      @include media-breakpoint-up('md') {
        order: 4;
      }

      @include media-breakpoint-up('xl') {
        order: 2;
      }

      .greetings-name {
        font-size: 32px;
        font-family: $bold-font-stack;
        color: $brand-primary-color;
      }

      .company-info {
        @include divider(0.75rem);
        line-height: 1.25rem;

        .company-info__name {
          font-size: 1rem;
        }
        .company-info__rewards-id {
          font-size: 0.75rem;
        }
      }

      .rewards-section {
        display: flex;
        flex-flow: column;
        font-size: 16px;
        color: $black;

        h2 {
          color: $brand-secondary-color;
          width: 100%;
          font-family: $branded-font-stack;
          font-size: 1.5rem;
          font-weight: normal;
          line-height: 2rem;
          margin: 0 0 10px 0;
        }

        h3 {
          font-family: $branded-font-stack;
          font-weight: normal;
          font-size: 1rem;
          text-transform: uppercase;
          margin: 0;

          span {
            display: block;
            font-size: 0.625rem;
            text-transform: initial;
          }
        }

        .value {
          font-family: $bold-font-stack;
          font-size: 1.5rem;
          line-height: 2rem;
        }

        .total-points {
          display: flex;
          flex-flow: column;
          margin-bottom: 10px;

          p {
            margin: 0;
            display: block;
            font-size: 0.625rem;
          }

          p:first-child {
            font-family: $branded-font-stack;
            font-weight: normal;
            font-size: 1rem;
            text-transform: uppercase;
          }
        }

        .tier-status {
          display: flex;
          flex-direction: row;
          margin-bottom: 8px;

          img {
            margin-left: 10px;
            width: 58px;
          }
        }
      }

      .details-link {
        font-size: 0.875rem;
        line-height: 1.125rem;
      }
    }

    .graph-overview {
      font-size: 1rem;

      @include media-breakpoint-up('md') {
        order: 2;
        grid-column: span 2;
      }

      @include media-breakpoint-up('xl') {
        font-size: 1.25rem;
        grid-column: span 3;
      }
    }

    .as-of-date {
      font-size: 0.875rem;
      color: $brand-secondary-color;

      @include media-breakpoint-up('md') {
        order: 3;
        grid-column: span 2;
      }

      @include media-breakpoint-up('xl') {
        grid-column: span 3;
      }
    }

    .sales-tile {
      order: 5;
      padding: 24px;
      background-color: $white;

      &.informational {
        padding: 0;

        img {
          display: block;
          width: 100%;
        }
      }

      &.svg-fill-graph {
        h2 {
          margin-bottom: 3px;
        }
      }

      &.stacked-bar {
        .stacked-bar-category:not(:last-child) {
          @include divider;
        }
      }

      h2 {
        font-family: $branded-font-stack;
        font-size: 1.5rem;
        font-weight: normal;
        color: $brand-secondary-color;
        text-transform: uppercase;
        margin: 0 0 24px 0;
      }

      h3 {
        font-family: $bold-font-stack;
        font-size: 1rem;
        margin: 0;
        color: $brand-secondary-color;

        &:has(.help) {
          display: flex;
          align-items: center;

          button {
            display: inline-block;
            width: 16px;
            height: 17px;
            margin-left: 6px;
          }
        }
      }

      table {
        width: 100%;
        margin-bottom: 6px;

        tr {
          display: flex;
          flex-direction: row;
          align-items: center;

          th {
            flex: 1;
            text-align: left;
            color: $brand-secondary-color;
          }

          td {
            font-size: 1.5rem;
            font-family: $bold-font-stack;
          }
        }
      }

      .graph {
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin-bottom: 9px;

        .graph__label-container {
          width: 100%;
          position: absolute;
          bottom: 0;

          .graph__label {
            position: absolute;
            width: 100%;
            display: flex;
            border-bottom: 1px solid $brand-secondary-color;
            z-index: 1;

            span {
              display: inline-flex;
              align-items: center;
              height: 19px;
              font-size: 0.625rem;
              font-family: $bold-font-stack;
              color: $white;
              background-color: $brand-secondary-color;
              padding: 0 14px 0 4px;
              text-transform: uppercase;
            }

            &.completed {
              span {
                padding-right: 4px;
              }
            }

            &.choice {
              bottom: 0;
            }

            &.select {
              bottom: 44px;
            }

            &.master {
              bottom: 88px;
            }

            &:after {
              content: '';
              display: block;
              height: 0;
              width: 0;
              border-left: 13px solid $brand-secondary-color;
              border-top: 19px solid transparent;
            }
          }
        }
      }

      p.value {
        font-size: 1.5rem;
        font-family: $bold-font-stack;
        margin: 0;
      }

      .caption, .multiplier {
        font-size: 0.875rem;
      }

      .multiplier {
        color: $brand-secondary-color;
      }

      .carousel{
        .slider-counter{
          margin-top: 10px;
        }

        .slider-counter-circle-container{
          padding-bottom: 0px !important;
        }
      }

      .totalDot {
        height: 12px;
        width: 12px;
        background-color: $white;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid $black;
      }

      .newDot {
        height: 12px;
        width: 12px;
        background-color: $brand-primary-color;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid $black;
      }

      .donut-chart {
        height: inherit;

        .apexcharts-canvas{

          .apexcharts-pie{

          }
        }

        .apexcharts-text.apexcharts-datalabel-label {
          font-family: $branded-font-stack !important;
          font-size: 15px !important;
        }

        .apexcharts-text.apexcharts-datalabel-value{
          font-family: $branded-font-stack !important;
          font-size: 15px !important;
        }
        .apexcharts-legends-text{
          font-family: $branded-font-stack !important;
          font-size: 22px !important;
        }

        .apexcharts-datalabels{
          font-size: 10px !important;
        }
      }

      .productType1 {
        background-color: #570987;
      }

      .productType2 {
        background-color: $brand-primary-color;
      }

      .productType3 {
        background-color: $med-dark-grey;
      }

      .productType4 {
        background-color: #05998C;
      }

      .productType5 {
        background-color: #F28C28;
      }

      .productType6 {
        background-color: #28d728;
      }

      .retained {
        background-color: $med-dark-grey;
      }

      .bar-chart-section {
        margin: auto;

        .numbers-display {
          color: $black;
          font-size: 16px;
          font-weight: 600;
        }

        .bar-chart{
          background-color: #CCCCCC;
          display: inline-block;
          height: 20px;
          margin-bottom: 18px;

          @media screen and (max-width: 833px) {
            margin-bottom: 14px;
          }

          @for $i from 0 through 100 {
            .ani-#{$i} {
              width: $i * 1%;
            }
          }

          .meter-max {
            display: inline-block;

            .currentVal {
              display: block;
              animation-name: sliding-bar-animation;
              animation-duration: .5s;
              height: 20px;
            }

            @keyframes sliding-bar-animation {
              from {width: 0}
              to {width: 100%}
            }
          }
        }
      }

      .legend-section-donut {
        margin: auto;
      }

      .legend-section {
        display: flex;
        justify-content: start;
        flex-flow: wrap;

        div {
          display: flex;
          flex: 1 1 50%;
          margin-bottom: 5px;
        }
      }

      .dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 100px;
        margin-right: 4px;
      }

      .legend {
        color: $black;
        font-size: 12px;
        margin-right: 10px;
      }
    }

    .infoTile {
      a {
        display: block;

        img {
          display: block;
          width: 100%;
        }
      }
    }

    .carousel {
      .slide-container {
        margin-bottom: 1rem;

        .slide {
          background-color: transparent;
        }
      }

      .pager, button.next, button.prev {
        display: none;
      }
    }
  }

  .tile-section {
    background-color: $brand-secondary-color;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../../assets/img/bg-pattern.png');
      background-size: 124px;
      opacity: 0.06;
    }

    .ragged-layout {
      @include content-container;
      @include media-breakpoint-up('md') {
        padding-top: 48px;
        padding-bottom: 48px;
      }

      .item-container {
        position: relative;

        @include media-breakpoint-up('md') {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-gap: 20px;
        }

        @include media-breakpoint-up('xl') {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include media-breakpoint-only('sm') {
          .merchandise-marketplace-tile {
            max-width: calc(100vw - 40px);
          }
        }

        .carousel {
          .slide-container {
            margin-bottom: 10px;

            .slide {
              background: none;

              .tile {
                margin: 0 auto;
              }
            }
          }

          .pager,
          button.prev,
          button.next {
            display: none;
          }
        }
      }
    }
  }
}
