@mixin date-button-styles {
  .date-button {
    background-color: transparent;
    padding: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

  .currentDay {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .currentDay::after {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    background-color: $brand-primary-color;
    border-radius: 50%;
    top: 26px;
  }

  td.not-submittable {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  button.not-submittable {
    pointer-events: none;
  }

  // .submittable {
  //     cursor: pointer;
  // }
  button.submittable:hover,
  .date-button.submittable.active {
    background-color: #e9e9e9;
  }

  .submitted {
    background-color: $brand-primary-color !important;
    color: #fff !important;
  }

  .date-view {
    .trackableDates {
      background: #e9e9e9;
    }
    // Targetting a div that does not have `.trackableDates` css class but its immediate sibling does
    div:not(.trackableDates) + .trackableDates {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    .today.trackableDates {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
}

.countNumber {
  color: $brand-primary-color; // same
  font-weight: 400; // same
}

.calenderDesk {
  @media screen and (min-width: 768px) {
    .trackTab,
    .date-view {
      max-width: 500px;
      margin: 1rem auto;
    }

    .submit-button {
      button.button {
        text-align: center;
        margin: 0 auto;
        border-radius: 4px;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    max-width: 1280px;
    margin: 0px auto;
    .promotion-information {
      padding: 2.5rem;
    }
    .promotion-name {
      width: 40%;

      .shortName {
        font-size: 21px;
      }

      .fullName {
        font-size: 42px;
      }

      .fullDescription {
        margin-right: 4px;
        font-size: 16px;
      }

      .endDate {
        font-size: 13px;
      }
    }
    .points-bar {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: flex-end;

      .points {
        font-size: 42px;

        .total span {
          font-size: 21px;
        }
      }
    }
    .promotion-coupons {
      display: flex;
    }
    .submit-button {
      width: auto;
    }
    .countNumber {
      // additional styles just for desktop:
      font-size: 90px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .tableCalender > tbody > tr:first-child > td:first-child {
      border-right: 1px solid #ccc;
    }
    .tableCalender {
      width: 100vw;
      background: #fff;
      margin: 0 calc(-50vw + 50%);
      tr.calRow {
        display: flex;
        justify-content: center;
        padding: 1rem 0;
      }
      td.calendar,
      td.trackTable {
        padding: 20px;
        width: 40%;
        // max-width: 480px;
        // margin: 0 120px;
        // .date-view {
        //     max-width: 400px;
        //     margin: auto;
        // }
      }
      td.non-standard-td {
        padding: 0;
      }

      calendar-header > button.next span {
        left: 63px;
      }
    }
    .trackNreview {
      display: none;
    }
    @include date-button-styles;
  }

  @media screen and (max-width: 1023px) {
    .trackTab,
    .date-view {
      padding-top: 40px;
    }
  }
}
@media screen and (max-width: 1023px) {
  .desktopView {
    display: none;
  }
}
// promotion header
.promotion-information {
  padding: 1rem;
  & p {
    margin: 0 0 4px 0;
  }
  .shortName {
    color: #6e6e6e;
    font-size: 13px;
    font-weight: bold;
  }

  .fullName {
    color: $brand-primary-color;

    @media screen and (max-width: 768px) {
      font-size: 21px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 34px;
    }
  }

  .fullDescription {
    color: #000;
    font-size: 13px;
    padding-top: 15px;
  }

  .endDate {
    font-size: 10px;
    padding-top: 5px;

    .calendar {
      width: 12px;
      height: 12px;
      margin-right: 2px;
    }
  }

  .points {
    display: flex;
    justify-content: flex-end;
    font-size: 21px;

    .earned {
      color: $brand-primary-color;
    }

    .total {
      span {
        font-size: 13px;
      }
    }
  }

  .react-sweet-progress {
    &-line {
      background: #ccc;
    }

    &-line-inner-status-active:before {
      animation: none;
    }

    &-symbol {
      display: none;
    }
  }

  .bonus-section {
    font-size: 11px;
    padding-top: 0.5rem;
    position: relative;

    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
    .bonusPts1,
    .bonusPts2 {
      padding-right: 2rem;
    }
    .bonusPts1::after,
    .bonusPts2::after {
      content: '';
      height: 1.75rem;
      border-right: 1px solid #ccc;
      position: absolute;
      top: -0.5rem;
      padding-left: 0.75rem;
    }

    .success-check {
      width: 12px;
      height: 12px;
      margin-right: 4px;
      vertical-align: bottom;
      fill: $brand-primary-color;

      @media screen and (min-width: 768px) {
        vertical-align: baseline;
      }
    }
  }
}

.trackNreview {
  padding: 1rem;
  background: #fff;

  .countNumber {
    font-size: 120px;
  }
  .react-tabs {

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin: 40px;
    }

    &__tab-list {
      text-align: center;
      font-size: 13px;
      width: 80%;
      margin: 0 auto;
    }

    &__tab {
      width: 50%;
      text-align: center;
    }

    &__tab--selected {
      background: transparent;
      border: none;
      border-bottom: 4px solid $brand-primary-color;
    }
  }

  @include date-button-styles;
}

.crement-button {
  cursor: pointer;
}
.crement-button:hover,
.crement-button:active {
  fill: $brand-primary-color;
  color: $brand-primary-color;
}

.cal-display-date, .cal-select-date {
  color: $brand-primary-color;
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 1024px) {
  .cal-question, .cal-display-date {
    font-size: 26px;
  }
}

@media screen and (min-width: 768px) {
  .cal-select-date{
    font-size: 21px;
  }
}
