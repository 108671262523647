.site-content > .content-wrapper .content {
  &.faq {
    .form-group {
      &.submit {
        button,
        .button {
          margin: 25px 10px 0px 0px;
        }

        @media screen and (max-width: 1023px) {
          button,
          .button {
            display: block;
            width: 100%;
            margin: 10px 0px 0px 0px;

            &:first-child {
              margin: 25px 0px 0px 0px;
            }
          }
        }
      }
    }
  }
}
