.site-content .content-wrapper {
  .content {
    &.generic-form {
      @include content-container-fluid;
      height: 100%;

      padding: 20px;

      @include media-breakpoint-up('md') {
        padding: 40px;
      }

      .content-description {
        width: inherit;
        max-width: 693px;
      }

      .content-body {
        background: none;

        form {
          max-width: 693px;
        }
      }
    }

    &.slim {
      max-width: 450px;
      padding: 20px;

      @include media-breakpoint-up('md') {
        padding: 40px;
      }

      .form-group {
        &.submit {
          border-top: solid 1px #aeaeae;
          padding: 15px 0;
        }
      }
    }

    &.wide {
      @include media-breakpoint-up('lg') {
        max-width: 1280px;
      }
      .form-group {
        max-width: 693px;
        &.submit {
          margin: 0;
          .button {
            width: 129px;
            padding-top: 0;

          }
        }
      }
    }

    .content-header {
      font-size: 2.125rem;
    }
  }
}
